import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarNota: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postNota(payload);
      payload.nota.id = response.data.id;
      commit(types.CRIAR_NOTA, { nota: payload.nota });
      await dispatch("actSelecionarNota", {
        id: payload.nota.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarNota: async ({ commit }, payload) => {
    try {
      payload.nota.criacao = undefined;
      payload.nota.criado = undefined;
      payload.nota.modificado = undefined;
      const response = await axios.putNota(payload);
      commit(types.EDITAR_NOTA, {
        nota: JSON.parse(response.config.data)["nota"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarNota: async ({ commit }, payload) => {
    try {
      const response = await axios.getNotasSelecionar(
        payload.id,
        payload.licenca
      );
      console.log("!!", response.data.response[0]);
      await commit(types.SELECIONAR_NOTA, {
        nota: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarNotas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getNotasListar(licenca);
      commit(types.LISTAR_NOTAS, {
        notas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraNota: async ({ dispatch }, payload) => {
    const nota = Object.assign({}, payload.nota);
    nota.lixeira = !nota.lixeira;
    nota.modificacao = payload.modificacao;
    dispatch("actEditarNota", {
      nota: nota,
      licenca: payload.licenca,
    });
  },

  actResetarNota: ({ commit }) => {
    commit(types.SELECIONAR_NOTA, { nota: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

export default [
  {
    name: "AdminPerfisLista",
    path: "/AdminPerfisLista",
    component: () => import("../_views/AdminPerfisLista"),
  },
  {
    name: "AdminPerfisForm",
    path: "/AdminPerfisForm",
    component: () => import("../_components/AdminPerfisForm"),
  },
  {
    name: "AdminPerfisModulosForm",
    path: "/AdminPerfisModulosForm",
    component: () => import("../_components/AdminPerfisModulosForm"),
  },
  {
    name: "AdminPerfisModulosLista",
    path: "/AdminPerfisModulosLista",
    component: () => import("../_components/AdminPerfisModulosLista"),
  },
];

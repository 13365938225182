export default [
  {
    name: "TabAtualizacoesLista",
    path: "/TabAtualizacoesLista",
    component: () => import("../_views/TabAtualizacoesLista"),
  },
  {
    name: "TabAtualizacoesForm",
    path: "/TabAtualizacoesForm",
    component: () => import("../_components/TabAtualizacoesForm"),
  },
];

export default [
  {
    name: "UtilTarefasLista",
    path: "/UtilTarefasLista",
    component: () => import("../_views/UtilTarefasLista"),
  },
  {
    name: "UtilTarefasForm",
    path: "/UtilTarefasForm",
    component: () => import("../_components/UtilTarefasForm"),
  },
];

import * as types from "./mutation-types";

export default {
  [types.LISTAR_BANCOS]: (state, { bancos }) => {
    state.staBancos = bancos;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};

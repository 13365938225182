import apiClient from "@/services/axios";

export default {
  postPFisica(pfisica) {
    return apiClient.post("/finPFisicas/cadastrar", pfisica);
  },

  putPFisica(pfisica) {
    return apiClient.put("/finPFisicas/editar", pfisica);
  },

  getPFisicasSelecionar(id, licenca) {
    return apiClient.get(`/finPFisicas/selecionar/${id}/${licenca}`);
  },

  getPFisicasListar(licenca) {
    return apiClient.get(`/finPFisicas/listar/${licenca}`);
  },

  getPaisesSelect() {
    return apiClient.get("/tabConsultas/listarPaises");
  },

  getObterDadosCep(cep) {
    return apiClient.get(`/consGerais/cepDados/${cep}`);
  },
};

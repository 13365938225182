export default {
  staErro: undefined,
  staDues: [],
  staDueSelecionado: undefined,
  staDueDadosCNPJ: undefined,
  staMoedasSelect: [],
  staUnidadeLocalRFBSelect: [],
  staRecAduanDespSelect: [],
  staRecAduanEmbSelect: [],
  staPuDuesNFes: [],
};

import apiClient from "@/services/axios";

export default {
  postRota(rota) {
    return apiClient.post("/cadRotas/cadastrar", rota);
  },

  putRota(rota) {
    return apiClient.put("/cadRotas/editar", rota);
  },

  getRotasSelecionar(id, licenca) {
    return apiClient.get(`/cadRotas/selecionar/${id}/${licenca}`);
  },

  getRotasListar(licenca) {
    return apiClient.get(`/cadRotas/listar/${licenca}`);
  },
};

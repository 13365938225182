import * as types from "./mutation-types";

export default {
  [types.LISTAR_UNIDADELOCALRFB]: (state, { unidadelocalrfb }) => {
    state.staUnidadeLocalRFB = unidadelocalrfb;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};

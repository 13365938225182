import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarPermisso: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postPermisso(payload);
      payload.permisso.id = response.data.id;
      commit(types.CRIAR_PERMISSO, { permisso: payload.permisso });
      var permisso = payload.permisso;
      dispatch("actSelecionarPermisso", { permisso });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarPermisso: async ({ commit }, payload) => {
    try {
      const response = await axios.putPermisso(payload);
      commit(types.EDITAR_PERMISSO, {
        permisso: JSON.parse(response.config.data)["permisso"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarPermisso: async ({ commit }, payload) => {
    try {
      const response = await axios.getPermissosSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_PERMISSO, {
        permisso: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarPermissos: async ({ commit }, licenca) => {
    try {
      const response = await axios.getPermissosListar(licenca);
      commit(types.LISTAR_PERMISSOS, {
        permissos: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actTransportadoresSelect: async ({ commit }, licenca) => {
    try {
      const response = await axios.getTransportadoresSelect(licenca);
      commit(types.SELECT_TRANSPORTADORES, {
        transportadoresSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraPermisso: async ({ dispatch }, payload) => {
    const permisso = Object.assign({}, payload.permisso);
    permisso.lixeira = !permisso.lixeira;
    permisso.modificacao = payload.modificacao;
    dispatch("actEditarPermisso", {
      permisso: permisso,
      licenca: payload.licenca,
    });
  },

  actResetarPermisso: ({ commit }) => {
    commit(types.SELECIONAR_PERMISSO, { permisso: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

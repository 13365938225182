import apiClient from "@/services/axios";

export default {
  postConveniada(conveniada) {
    return apiClient.post("/lojaConveniadas/cadastrar", conveniada);
  },

  putConveniada(conveniada) {
    return apiClient.put("/lojaConveniadas/editar", conveniada);
  },

  getConveniadas(licenca) {
    return apiClient.get(`/lojaConveniadas/listar/${licenca}`);
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_NOTA]: (state, { nota }) => {
    state.staNotas.push(nota);
  },
  [types.EDITAR_NOTA]: (state, { nota }) => {
    const indice = state.staNotas.findIndex((t) => t.id === nota.id);
    state.staNotas.splice(indice, 1, nota);
  },
  [types.DELETAR_NOTA]: (state, { nota }) => {
    const indice = state.staNotas.findIndex((t) => t.id === nota.id);
    state.staNotas.splice(indice, 1);
  },
  [types.LISTAR_NOTAS]: (state, { notas }) => {
    state.staNotas = notas;
  },
  [types.SELECIONAR_NOTA]: (state, { nota }) => {
    state.staNotaSelect = nota;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};

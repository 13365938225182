import apiClient from "@/services/axios";

export default {
  postCrediario(crediario) {
    return apiClient.post("/lojaCrediarios/cadastrar", crediario);
  },

  putCrediario(crediario) {
    return apiClient.put("/lojaCrediarios/editar", crediario);
  },

  getCrediariosAbertos(licenca) {
    return apiClient.get(`/lojaCrediarios/listaAbertos/${licenca}`);
  },

  getCrediariosCobranca(licenca) {
    return apiClient.get(`/lojaCrediarios/listaCobranca/${licenca}`);
  },

  getCrediariosArquivados(licenca) {
    return apiClient.get(`/lojaCrediarios/listaArquivados/${licenca}`);
  },

  getCrediariosEspecifico(id, licenca) {
    return apiClient.get(`/lojaCrediarios/listaEspecifico/${id}/${licenca}`);
  },

  getCrediariosClienteId(id, licenca) {
    return apiClient.get(`/lojaCrediarios/listaCredCliId/${id}/${licenca}`);
  },

  //

  postCrediarioParcela(parcela) {
    return apiClient.post("/lojaCrediariosParcelas/cadastrar", parcela);
  },

  putCrediarioParcela(parcela) {
    return apiClient.put("/lojaCrediariosParcelas/editar", parcela);
  },

  getCrediarioParcelas(id, licenca) {
    return apiClient.get(`/lojaCrediariosParcelas/id/${id}/${licenca}`);
  },

  //

  getConveniadas(licenca) {
    return apiClient.get(`/lojaConveniadas/listar/${licenca}`);
  },
  getSelectClientes(licenca) {
    return apiClient.get(`/lojaClientes/select/${licenca}`);
  },
  getSelectConveniadas(licenca) {
    return apiClient.get(`/lojaConveniadas/select/${licenca}`);
  },
  getCompletoPDF(tipoCred, licenca) {
    return apiClient.get(
      `/lojaCrediarios/crediariosPDF/${tipoCred}/${licenca}`
    );
  },
};

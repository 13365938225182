import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actListarUnidadeLocalRFB: async ({ commit }) => {
    try {
      const response = await axios.getUnidadeLocalRFBListar();
      commit(types.LISTAR_UNIDADELOCALRFB, {
        unidadelocalrfb: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

export default [
  {
    name: "AdminUsuariosLista",
    path: "/AdminUsuariosLista",
    component: () => import("./../_views/AdminUsuariosLista"),
  },
  {
    name: "AdminUsuariosForm",
    path: "/AdminUsuariosForm",
    component: () => import("./../_components/AdminUsuariosForm"),
  },
  {
    name: "AdminUsuariosGruposForm",
    path: "/AdminUsuariosGruposForm",
    component: () => import("./../_components/AdminUsuariosGruposForm"),
  },
];

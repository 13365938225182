export const SETAR_ERRO = "SETAR_ERRO";
// export const USUARIO_INSERT = "USUARIO_INSERT";
// export const USUARIO_UPDATE = "USUARIO_UPDATE";
// export const USUARIO_DELETE = "USUARIO_DELETE";
// export const USUARIOS_LISTA = "USUARIOS_LISTA";
export const USUARIO_SELECIONADO = "USUARIO_SELECIONADO";
export const USUARIOS_LISTA = "USUARIOS_LISTA";
export const USUARIOS_GRUPOS = "USUARIOS_GRUPOS";
export const USUARIO_CAIXAS = "USUARIO_CAIXAS";
export const USUARIO_CAIXA_UPDATE = "USUARIO_CAIXA_UPDATE";

import apiClient from "@/services/axios";

export default {
  postTarefa(tarefa) {
    return apiClient.post("/utilTarefas/cadastrar", tarefa);
  },

  putTarefa(tarefa) {
    return apiClient.put(`/utilTarefas/editar`, tarefa);
  },

  getTarefas(licenca) {
    return apiClient.get(`/utilTarefas/listar/${licenca}`);
  },

  getTarefasArq(licenca) {
    return apiClient.get(`/utilTarefas/listaArq/${licenca}`);
  },

  getUsuarios(licenca) {
    return apiClient.get(`/adminUsuarios/listar/${licenca}`);
  },

  postMail(email) {
    return apiClient.post("/mailSend/clomosenviar", email);
  },
};

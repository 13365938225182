import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules,
  state: {
    staSnackbars: [],
  },
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.staSnackbars.unshift(snackbar);
    },
    HIDE_SNACKBAR(state, payload) {
      var snack = state.staSnackbars.find((i) => i.indexing == payload);
      if (snack != undefined) {
        snack.showing = false;
      }
    },
    CLEAR_SNACKBAR(state) {
      state.staSnackbars.pop();
    },
  },
  actions: {
    actShowSnackbar({ commit }, snackbar) {
      commit("SET_SNACKBAR", snackbar);
      setTimeout(() => {
        commit("HIDE_SNACKBAR", snackbar.indexing);
      }, 4000);
    },
    actClearSnackbar({ commit }) {
      commit("CLEAR_SNACKBAR");
    },
  },
});

import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import pt from "vuetify/lib/locale/pt";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/es5/util/colors";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.lightBlue.darken4,
        // primary: colors.indigo.darken4,
        // primary: colors.shades.black,
        // primary: colors.grey.darken3,
        // primary: colors.green.darken4,
        // primary: colors.amber.darken4,
        // primary: colors.deepOrange.accent4,
        // primary: colors.purple.darken3,
        // primary: colors.brown.lighten1,
        // primary: colors.pink.accent2,
        // primary: colors.teal.accent4,
        // primary: colors.purple.accent2,
        // primary: colors.teal.darken3,
      },
      dark: {
        primary: {
          base: colors.green.darken3,
          darken1: colors.teal.accent4,
        },
        accent: "#250032",
        secondary: "#97812F",
        info: {
          base: "#1FFFF1",
          darken1: "#450b5a",
          darken2: "#1125c0",
          darken3: "#40bfa4",
        },
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
        anchor: "#1FFFF1",
      },
    },
  },
};

export default new Vuetify(opts);

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarLicenca: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postLicenca(payload);
      payload.licenca.id = response.data.id;
      commit(types.CRIAR_LICENCA, { licenca: payload.licenca });
      var licenca = payload.licenca;
      dispatch("actSelecionarLicenca", { licenca });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarLicenca: async ({ commit }, payload) => {
    try {
      const response = await axios.putLicenca(payload);
      commit(types.EDITAR_LICENCA, {
        licenca: JSON.parse(response.config.data)["licenca"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarLicencas: async ({ commit }) => {
    try {
      const response = await axios.getLicencas();
      commit(types.LISTAR_LICENCAS, {
        licencas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarLicenca: ({ commit }, payload) => {
    commit(types.SELECIONAR_LICENCA, payload);
  },

  actAtivarLicenca: async ({ dispatch }, payload) => {
    const licenca = Object.assign({}, payload.licenca);
    licenca.ativo = !licenca.ativo;
    dispatch("actEditarLicenca", { licenca });
  },

  resetarLicenca: ({ commit }) => {
    commit(types.SELECIONAR_LICENCA, { licenca: undefined });
  },

  actSelectPerfis: async ({ commit }) => {
    try {
      const response = await axios.getSelectPerfis();
      commit(types.SELECT_PERFIS, {
        perfisSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

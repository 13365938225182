import * as types from "./mutation-types";

export default {
  [types.CRIAR_PERFIL]: (state, { perfil }) => {
    state.staPerfis.push(perfil);
  },
  [types.EDITAR_PERFIL]: (state, { perfil }) => {
    const indice = state.staPerfis.findIndex((t) => t.id === perfil.id);
    state.staPerfis.splice(indice, 1, perfil);
  },
  [types.LISTAR_PERFIS]: (state, { perfis }) => {
    state.staPerfis = perfis;
  },
  [types.SELECIONAR_PERFIL]: (state, { perfil }) => {
    state.staPerfilSelecionado = perfil;
  },
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },

  //
  [types.CRIAR_PERFISMODULOS]: (state, { perfMod }) => {
    state.staPerfisModulos.push(perfMod);
  },
  [types.EDITAR_PERFISMODULOS]: (state, { perfMod }) => {
    const indice = state.staPerfisModulos.findIndex((t) => t.id === perfMod.id);
    state.staPerfisModulos.splice(indice, 1, perfMod);
  },
  [types.DELETAR_PERFISMODULOS]: (state, { perfMod }) => {
    const indice = state.staPerfisModulos.findIndex((t) => t.id === perfMod.id);
    state.staPerfisModulos.splice(indice, 1);
  },
  [types.LISTAR_PERFISMODULOS]: (state, { perfisModulos }) => {
    state.staPerfisModulos = perfisModulos;
  },
  [types.SELECT_MODULOS]: (state, { modulosSelect }) => {
    state.staModulosSelect = modulosSelect;
  },
};

import apiClient from "@/services/axios";

export default {
  postUsuario(usuario) {
    return apiClient.post("/adminUsuarios/cadastrar", usuario);
  },

  putUsuario(usuario) {
    return apiClient.put("/adminUsuarios/editar", usuario);
  },

  getUsuarios(licenca) {
    return apiClient.get(`/adminUsuarios/listar/${licenca}`);
  },

  //

  postUsuariosGrupos(usuGru) {
    return apiClient.post("/adminUsuariosGrupos/cadastrar", usuGru);
  },

  putUsuariosGrupos(usuGru) {
    return apiClient.put("/adminUsuariosGrupos/editar", usuGru);
  },

  deleteUsuariosGrupos(idUsuGru, licenca) {
    return apiClient.delete(
      `/adminUsuariosGrupos/excluir/${idUsuGru}/${licenca}`
    );
  },

  getUsuariosGrupos(idUsuario, licenca) {
    return apiClient.get(`/adminUsuariosGrupos/listar/${idUsuario}/${licenca}`);
  },

  //

  getSelectGrupos() {
    return apiClient.get("/adminGrupos/select");
  },

  postEnviarEmail(email) {
    return apiClient.post("/mailSend/clomosenviar", email);
  },
};

export default [
  {
    name: "LojaClientesLista",
    path: "/LojaClientesLista",
    component: () => import("../_views/LojaClientesLista"),
  },
  {
    name: "LojaClientesComprasForm",
    path: "/LojaClientesComprasForm",
    component: () => import("../_components/LojaClientesComprasForm"),
  },
  {
    name: "LojaClientesComprasLista",
    path: "/LojaClientesComprasLista",
    component: () => import("../_components/LojaClientesComprasLista"),
  },
  {
    name: "LojaClientesForm",
    path: "/LojaClientesForm",
    component: () => import("../_components/LojaClientesForm"),
  },
];

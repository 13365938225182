import Vue from "vue";
// import { Quill } from "quill";
import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.snow.css";

// import ImageResize from "quill-image-resize-module";
// Quill.register("modules/imageResize", ImageResize);

var formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  // 'image'
  // 'video'
];

var toolbarOptions = [
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ color: [] }, { background: [] }],
  // [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  // [{ script: "sub" }, { script: "super" }],
  // [{ size: ["small", false, "large", "huge"] }],
  // [{ color: [] }, { background: [] }],
  // [{ font: [] }],
  [{ align: [] }],
  [{ indent: "-1" }, { indent: "+1" }],
  // ["clean"],
  ["link"],
  // ["image"],
];

const defaultOptions = {
  theme: "snow",
  formats: formats,
  modules: {
    toolbar: toolbarOptions,
  },

  placeholder: "Escreva a aqui...",
};

Vue.use(VueQuillEditor, defaultOptions);

export default {
  components: {
    VueQuillEditor,
  },
};

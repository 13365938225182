import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarProprietario: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postProprietario(payload);
      payload.proprietario.id = response.data.id;
      commit(types.CRIAR_PROPRIETARIO, { proprietario: payload.proprietario });
      dispatch("actSelecionarProprietario", {
        id: payload.proprietario.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarProprietario: async ({ commit }, payload) => {
    try {
      const response = await axios.putProprietario(payload);
      commit(types.EDITAR_PROPRIETARIO, {
        proprietario: JSON.parse(response.config.data)["proprietario"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarProprietario: async ({ commit }, payload) => {
    try {
      const response = await axios.getProprietariosSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_PROPRIETARIO, {
        proprietario: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarProprietarios: async ({ commit }, licenca) => {
    try {
      const response = await axios.getProprietariosListar(licenca);
      commit(types.LISTAR_PROPRIETARIOS, {
        proprietarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actPaisesSelect: async ({ commit }) => {
    try {
      const response = await axios.getPaisesSelect();
      commit(types.SELECT_PAISES, {
        paisesSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraProprietario: async ({ dispatch }, payload) => {
    const proprietario = Object.assign({}, payload.proprietario);
    proprietario.lixeira = !proprietario.lixeira;
    proprietario.modificacao = payload.modificacao;
    dispatch("actEditarProprietario", {
      proprietario: proprietario,
      licenca: payload.licenca,
    });
  },

  actExcluirProprietario: async ({ dispatch }, payload) => {
    const proprietario = Object.assign({}, payload.proprietario);
    proprietario.excluido = true;
    proprietario.modificacao = payload.modificacao;
    dispatch("actEditarProprietario", {
      proprietario: proprietario,
      licenca: payload.licenca,
    });
  },

  actResetarProprietario: ({ commit }) => {
    commit(types.SELECIONAR_PROPRIETARIO, { proprietario: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarTransportador: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postTransportador(payload);
      payload.transportador.id = response.data.id;
      commit(types.CRIAR_TRANSPORTADOR, {
        transportador: payload.transportador,
      });
      dispatch("actSelecionarTransportador", {
        id: payload.transportador.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarTransportador: async ({ commit }, payload) => {
    try {
      const response = await axios.putTransportador(payload);
      commit(types.EDITAR_TRANSPORTADOR, {
        transportador: JSON.parse(response.config.data)["transportador"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarTransportador: async ({ commit }, payload) => {
    try {
      const response = await axios.getTransportadoresSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_TRANSPORTADOR, {
        transportador: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarTransportadores: async ({ commit }, licenca) => {
    try {
      const response = await axios.getTransportadoresListar(licenca);
      commit(types.LISTAR_TRANSPORTADORES, {
        transportadores: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actPaisesSelect: async ({ commit }) => {
    try {
      const response = await axios.getPaisesSelect();
      commit(types.SELECT_PAISES, {
        paisesSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraTransportador: async ({ dispatch }, payload) => {
    const transportador = Object.assign({}, payload.transportador);
    transportador.lixeira = !transportador.lixeira;
    transportador.modificacao = payload.modificacao;
    dispatch("actEditarTransportador", {
      transportador: transportador,
      licenca: payload.licenca,
    });
  },

  actExcluirTransportador: async ({ dispatch }, payload) => {
    const transportador = Object.assign({}, payload.transportador);
    transportador.excluido = true;
    transportador.modificacao = payload.modificacao;
    dispatch("actEditarTransportador", {
      transportador: transportador,
      licenca: payload.licenca,
    });
  },

  actResetarTransportador: ({ commit }) => {
    commit(types.SELECIONAR_TRANSPORTADOR, { transportador: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

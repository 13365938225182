import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarDue: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postDue(payload);
      payload.due.id = response.data.id;
      commit(types.CRIAR_DUE, { due: payload.due });
      dispatch("actSelecionarDue", {
        id: payload.due.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarDue: async ({ commit }, payload) => {
    try {
      const response = await axios.putDue(payload);
      commit(types.EDITAR_DUE, {
        due: JSON.parse(response.config.data)["due"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarDue: async ({ commit }, payload) => {
    try {
      const response = await axios.getDuesSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_DUE, {
        due: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarDues: async ({ commit }, licenca) => {
    try {
      const response = await axios.getDuesListar(licenca);
      commit(types.LISTAR_DUES, {
        dues: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraDue: async ({ dispatch }, payload) => {
    const due = Object.assign({}, payload.due);
    due.lixeira = !due.lixeira;
    due.modificacao = payload.modificacao;
    dispatch("actEditarDue", {
      due: due,
      licenca: payload.licenca,
    });
  },

  actExcluirDue: async ({ dispatch }, payload) => {
    const due = Object.assign({}, payload.due);
    due.excluido = true;
    due.modificacao = payload.modificacao;
    dispatch("actEditarDue", {
      due: due,
      licenca: payload.licenca,
    });
  },

  actObterRazao: async ({ commit }, payload) => {
    const response = await axios.getDadosCNPJ(payload.cnpj);
    commit(types.OBTER_DADOSCNPJ, {
      dadosCNPJ: response.data,
    });
  },

  actResetarDue: ({ commit }) => {
    commit(types.SELECIONAR_DUE, { due: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },

  actMoedasSelect: async ({ commit }) => {
    try {
      const response = await axios.getMoedasSelect();
      commit(types.SELECT_MOEDAS, {
        moedasSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actUnidadeLocalRFBSelect: async ({ commit }) => {
    try {
      const response = await axios.getUnidadeLocalRFBSelect();
      commit(types.SELECT_UNIDADELOCALRFB, {
        unidadeLocalRFBSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actRecAduanDespSelect: async ({ commit }, payload) => {
    try {
      const response = await axios.getRecintoAduaneiroSelect(payload);
      commit(types.SELECT_RECADUANDESP, {
        recAduanDespSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actRecAduanEmbSelect: async ({ commit }, payload) => {
    try {
      const response = await axios.getRecintoAduaneiroSelect(payload);
      commit(types.SELECT_RECADUANEMB, {
        recAduanEmbSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
  actListarNFes: async ({ commit }, payload) => {
    try {
      console.log(payload.idDue, payload.licenca);
      const response = await axios.getDuesNFes(payload.idDue, payload.licenca);
      commit(types.LISTAR_DUENFS, {
        dueNFs: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

import { routes as login } from "../resources/login";
import { routes as aplicacao } from "./../resources/aplicacao";
import Erro404 from "@/components/Erro404";

export default [
  ...login,
  ...aplicacao,
  { path: "", redirect: "/Login" },
  { path: "*", component: Erro404 },
];

import apiClient from "@/services/axios";

export default {
  postPop(pop) {
    return apiClient.post("/utilPops/cadastrar", pop);
  },

  putPop(pop) {
    return apiClient.put("/utilPops/editar", pop);
  },

  getPops(licenca) {
    return apiClient.get(`/utilPops/listar/${licenca}`);
  },

  getUsuarios(licenca) {
    return apiClient.get(`/adminUsuarios/listar/${licenca}`);
  },
};

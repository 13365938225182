export default [
  {
    name: "AdminModulosLista",
    path: "/AdminModulosLista",
    component: () => import("../_views/AdminModulosLista"),
  },
  {
    name: "AdminModulosForm",
    path: "/AdminModulosForm",
    component: () => import("../_components/AdminModulosForm"),
  },
  {
    name: "AdminModulosCompForm",
    path: "/AdminModulosCompForm",
    component: () => import("../_components/AdminModulosCompForm"),
  },
  {
    name: "AdminModulosCompLista",
    path: "/AdminModulosCompLista",
    component: () => import("../_components/AdminModulosCompLista"),
  },
];

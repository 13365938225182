import apiClient from "@/services/axios";

export default {
  postEmpresa(empresa) {
    return apiClient.post("/cadEmpresas/cadastrar", empresa);
  },

  putEmpresa(empresa) {
    return apiClient.put("/cadEmpresas/editar", empresa);
  },

  getEmpresasSelecionar(id, licenca) {
    return apiClient.get(`/cadEmpresas/selecionar/${id}/${licenca}`);
  },

  getEmpresasListar(licenca) {
    return apiClient.get(`/cadEmpresas/listar/${licenca}`);
  },

  getPaisesSelect() {
    return apiClient.get("/tabConsultas/listarPaises");
  },

  getObterDadosCep(cep) {
    return apiClient.get(`/consGerais/cepDados/${cep}`);
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_TRANSPORTADOR]: (state, { transportador }) => {
    state.staTransportadores.push(transportador);
  },
  [types.EDITAR_TRANSPORTADOR]: (state, { transportador }) => {
    const indice = state.staTransportadores.findIndex(
      (t) => t.id === transportador.id
    );
    state.staTransportadores.splice(indice, 1, transportador);
  },
  [types.DELETAR_TRANSPORTADOR]: (state, { transportador }) => {
    const indice = state.staTransportadores.findIndex(
      (t) => t.id === transportador.id
    );
    state.staTransportadores.splice(indice, 1);
  },
  [types.LISTAR_TRANSPORTADORES]: (state, { transportadores }) => {
    state.staTransportadores = transportadores;
  },
  [types.SELECIONAR_TRANSPORTADOR]: (state, { transportador }) => {
    state.staTransportadorSelect = transportador;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_PAISES]: (state, { paisesSelect }) => {
    state.staPaisesSelect = paisesSelect;
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_VEICULO]: (state, { veiculo }) => {
    state.staVeiculos.push(veiculo);
  },
  [types.EDITAR_VEICULO]: (state, { veiculo }) => {
    const indice = state.staVeiculos.findIndex((v) => v.id === veiculo.id);
    state.staVeiculos.splice(indice, 1, veiculo);
  },
  [types.DELETAR_VEICULO]: (state, { veiculo }) => {
    const indice = state.staVeiculos.findIndex((v) => v.id === veiculo.id);
    state.staVeiculos.splice(indice, 1);
  },
  [types.LISTAR_VEICULOS]: (state, { veiculos }) => {
    state.staVeiculos = veiculos;
  },
  [types.SELECIONAR_VEICULO]: (state, { veiculo }) => {
    state.staVeiculoSelect = veiculo;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_TRANSPORTADORES]: (state, { transportadoresSelect }) => {
    state.staTransportadoresSelect = transportadoresSelect;
  },

  [types.SELECT_PROPRIETARIOS]: (state, { proprietariosSelect }) => {
    state.staProprietariosSelect = proprietariosSelect;
  },

  [types.SELECT_MOTORISTAS]: (state, { motoristasSelect }) => {
    state.staMotoristasSelect = motoristasSelect;
  },

  [types.SELECT_VEICULOS]: (state, { veiculosSelect }) => {
    state.staVeiculosVincSelect = veiculosSelect;
  },

  [types.SELECT_ESTADOS]: (state, { estadosSelect }) => {
    state.staEstadosSelect = estadosSelect;
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_PFISICA]: (state, { pfisica }) => {
    state.staPFisicas.push(pfisica);
  },
  [types.EDITAR_PFISICA]: (state, { pfisica }) => {
    const indice = state.staPFisicas.findIndex((t) => t.id === pfisica.id);
    state.staPFisicas.splice(indice, 1, pfisica);
  },
  [types.DELETAR_PFISICA]: (state, { pfisica }) => {
    const indice = state.staPFisicas.findIndex((t) => t.id === pfisica.id);
    state.staPFisicas.splice(indice, 1);
  },
  [types.LISTAR_PFISICAS]: (state, { pfisicas }) => {
    state.staPFisicas = pfisicas;
  },
  [types.SELECIONAR_PFISICA]: (state, { pfisica }) => {
    state.staPFisicaSelect = pfisica;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_PAISES]: (state, { paisesSelect }) => {
    state.staPaisesSelect = paisesSelect;
  },
};

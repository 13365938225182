import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import vuelidate from "@/plugins/vuelidate";
import vuemask from "@/plugins/vuemask";
import vuequill from "@/plugins/vuequill";
import vuemoney from "@/plugins/vuemoney";

Vue.config.productionTip = false;
Vue.prototype.$token = undefined;

new Vue({
  router,
  store,
  vuetify,
  vuelidate,
  vuemask,
  vuequill,
  vuemoney,
  render: (h) => h(App),
}).$mount("#app");

document.title = "Clomos";

// no arquivo package.json
// ~ atualiza versao patch
// ^ atualiza versao minor

import axios from "../_services/axios";
// import * as storage from "../storage";
import * as types from "./mutation-types";

export const actLogin = ({ dispatch }, payload) => {
  return axios.postLogin(payload).then((response) => {
    dispatch("ActionSetToken", response.data.token);
    dispatch("ActionSetUser", response.data.user);
  });
};

export const ActionSetUser = ({ commit }, payload) => {
  commit(types.SET_USER, payload);
};
export const ActionSetToken = ({ commit }, payload) => {
  // storage.setLocalToken(payload); // armazena token localmente
  commit(types.SET_TOKEN, payload);
};
export const actLicenca = ({ commit }, payload) => {
  commit(types.SET_LICENCA, payload);
};

export const actPermissoes = ({ commit }, payload) => {
  return axios
    .getPermissoes(payload.idUsuario, payload.licenca)
    .then((response) => {
      commit(types.SET_PERMISSOES, {
        permissoes: response.data.response,
      });
    });
};

//dns1.w24.uni5.net

import apiClient from "@/services/axios";

export default {
  postCliente(cliente) {
    return apiClient.post("/lojaClientes/cadastrar", cliente);
  },

  putCliente(cliente) {
    return apiClient.put("/lojaClientes/editar", cliente);
  },

  getClientes(licenca) {
    return apiClient.get(`/lojaClientes/listar/${licenca}`);
  },

  //

  postClienteCompra(compra) {
    return apiClient.post("/lojaClienteCompras/cadastrar", compra);
  },

  putClienteCompra(compra) {
    return apiClient.put("/lojaClienteCompras/editar", compra);
  },

  getClienteCompras(id_cliente, licenca) {
    return apiClient.get(`/lojaClienteCompras/listar/${id_cliente}/${licenca}`);
  },

  //

  getSelectConveniadas(licenca) {
    return apiClient.get(`/lojaConveniadas/select/${licenca}`);
  },
};

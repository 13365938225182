import * as types from "./mutation-types";

export default {
  [types.CRIAR_MODULO]: (state, { modulo }) => {
    state.modulos.push(modulo);
  },
  [types.EDITAR_MODULO]: (state, { modulo }) => {
    const indice = state.modulos.findIndex((t) => t.id === modulo.id);
    state.modulos.splice(indice, 1, modulo);
  },
  [types.LISTAR_MODULOS]: (state, { modulos }) => {
    state.modulos = modulos;
  },
  [types.SELECIONAR_MODULO]: (state, { modulo }) => {
    state.moduloSelecionado = modulo;
  },
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
  //
  [types.CRIAR_MODULOSCOMPONENTES]: (state, { modComp }) => {
    state.modulosComponentes = modComp;
  },
  [types.EDITAR_MODULOSCOMPONENTES]: (state, { modComp }) => {
    const indice = state.modulosComponentes.findIndex(
      (t) => t.id === modComp.id
    );
    state.modulosComponentes.splice(indice, 1, modComp);
  },
  [types.LISTAR_MODULOSCOMPONENTES]: (state, { modulosComponentes }) => {
    state.modulosComponentes = modulosComponentes;
  },
  //
};

import apiClient from "@/services/axios";

export default {
  postVeiculo(veiculo) {
    return apiClient.post("/cadVeiculos/cadastrar", veiculo);
  },

  putVeiculo(veiculo) {
    return apiClient.put("/cadVeiculos/editar", veiculo);
  },

  getVeiculosSelecionar(id, licenca) {
    return apiClient.get(`/cadVeiculos/selecionar/${id}/${licenca}`);
  },

  getVeiculosListar(licenca) {
    return apiClient.get(`/cadVeiculos/listar/${licenca}`);
  },

  getTransportadoresSelect(licenca) {
    return apiClient.get(`/cadTransportadores/select/${licenca}`);
  },

  getProprietariosSelect(licenca) {
    return apiClient.get(`/cadProprietarios/select/${licenca}`);
  },

  getMotoristasSelect(licenca) {
    return apiClient.get(`/cadMotoristas/select/${licenca}`);
  },

  getVeiculosSelect(licenca) {
    return apiClient.get(`/cadVeiculos/selectvinc/${licenca}`);
  },

  getEstadosSelect() {
    return apiClient.get("/tabConsultas/listarEstados");
  },
};

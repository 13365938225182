export default [
  {
    name: "FinPJuridicasLista",
    path: "/FinPJuridicasLista",
    component: () => import("../_views/FinPJuridicasLista"),
  },
  {
    name: "FinPJuridicasForm",
    path: "/FinPJuridicasForm",
    component: () => import("../_components/FinPJuridicasForm"),
  },
];

export default [
  {
    name: "AdminLicencasLista",
    path: "/AdminLicencasLista",
    component: () => import("../_views/AdminLicencasLista"),
  },
  {
    name: "AdminLicencasForm",
    path: "/AdminLicencasForm",
    component: () => import("../_components/AdminLicencasForm"),
  },
];

export default [
  {
    name: "CadLocaisLista",
    path: "/CadLocaisLista",
    component: () => import("../_views/CadLocaisLista"),
  },
  {
    name: "CadLocaisForm",
    path: "/CadLocaisForm",
    component: () => import("../_components/CadLocaisForm"),
  },
];

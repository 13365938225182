export default [
  {
    name: "LojaConveniadasLista",
    path: "/LojaConveniadasLista",
    component: () => import("../_views/LojaConveniadasLista"),
  },
  {
    name: "LojaConveniadasForm",
    path: "/LojaConveniadasForm",
    component: () => import("../_components/LojaConveniadasForm"),
  },
];

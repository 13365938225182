import * as types from "./mutation-types";

export default {
  [types.CRIAR_TAREFA]: (state, { tarefa }) => {
    state.tarefas.push(tarefa);
  },
  [types.EDITAR_TAREFA]: (state, { tarefa }) => {
    const indice = state.tarefas.findIndex((t) => t.id === tarefa.id);
    state.tarefas.splice(indice, 1, tarefa);
  },
  [types.DELETAR_TAREFA]: (state, { tarefa }) => {
    const indice = state.tarefas.findIndex((t) => t.id === tarefa.id);
    state.tarefas.splice(indice, 1);
  },
  [types.LISTAR_TAREFAS]: (state, { tarefas }) => {
    state.tarefas = tarefas;
  },
  [types.SELECIONAR_TAREFA]: (state, { tarefa }) => {
    state.tarefaSelecionado = tarefa;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
  [types.LISTAR_USUARIOS]: (state, { listaUsuarios }) => {
    state.listaUsuarios = listaUsuarios;
  },
  [types.MODOLISTA_TAREFAS]: (state, { stoListaTar }) => {
    state.stoListaTar = stoListaTar;
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarPJuridica: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postPJuridica(payload);
      payload.pjuridica.id = response.data.id;
      commit(types.CRIAR_PJURIDICA, { pjuridica: payload.pjuridica });
      dispatch("actSelecionarPJuridica", {
        id: payload.pjuridica.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarPJuridica: async ({ commit }, payload) => {
    try {
      const response = await axios.putPJuridica(payload);
      commit(types.EDITAR_PJURIDICA, {
        pjuridica: JSON.parse(response.config.data)["pjuridica"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarPJuridica: async ({ commit }, payload) => {
    try {
      const response = await axios.getPJuridicasSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_PJURIDICA, {
        pjuridica: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarPJuridicas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getPJuridicasListar(licenca);
      commit(types.LISTAR_PJURIDICAS, {
        pjuridicas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actPaisesSelect: async ({ commit }) => {
    try {
      const response = await axios.getPaisesSelect();
      commit(types.SELECT_PAISES, {
        paisesSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraPJuridica: async ({ dispatch }, payload) => {
    const pjuridica = Object.assign({}, payload.pjuridica);
    pjuridica.lixeira = !pjuridica.lixeira;
    pjuridica.modificacao = payload.modificacao;
    dispatch("actEditarPJuridica", {
      pjuridica: pjuridica,
      licenca: payload.licenca,
    });
  },

  actResetarPJuridica: ({ commit }) => {
    commit(types.SELECIONAR_PJURIDICA, { pjuridica: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

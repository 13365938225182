import administrador from "../../administrador/_routes/routes";
import adminUsuarios from "../../adminUsuarios/_routes/routes";
import adminGrupos from "../../adminGrupos/_routes/routes";
import adminLicencas from "../../adminLicencas/_routes/routes";
import adminComponentes from "../../adminComponentes/_routes/routes";
import adminModulos from "../../adminModulos/_routes/routes";
import adminPerfis from "../../adminPerfis/_routes/routes";
import loja from "../../loja/_routes/routes";
import lojaClientes from "../../lojaClientes/_routes/routes";
import lojaConveniadas from "../../lojaConveniadas/_routes/routes";
import lojaCrediarios from "../../lojaCrediarios/_routes/routes";
import lojaEmpresas from "../../lojaEmpresas/_routes/routes";
import utilitarios from "../../utilitarios/_routes/routes";
import utilPops from "../../utilPops/_routes/routes";
import utilNotas from "../../utilNotas/_routes/routes";
import utilTarefas from "../../utilTarefas/_routes/routes";
import portalunico from "../../portalunico/_routes/routes";
import puDues from "../../puDues/_routes/routes";
import cadastros from "../../cadastros/_routes/routes";
import cadRotas from "../../cadRotas/_routes/routes";
import cadDespachantes from "../../cadDespachantes/_routes/routes";
import cadProprietarios from "../../cadProprietarios/_routes/routes";
import cadMotoristas from "../../cadMotoristas/_routes/routes";
import cadEmpresas from "../../cadEmpresas/_routes/routes";
import cadTransportadores from "../../cadTransportadores/_routes/routes";
import cadPermissos from "../../cadPermissos/_routes/routes";
import cadNumeracoes from "../../cadNumeracoes/_routes/routes";
import cadVeiculos from "../../cadVeiculos/_routes/routes";
import cadLocais from "../../cadLocais/_routes/routes";
import financeiro from "../../financeiro/_routes/routes";
import finPFisicas from "../../finPFisicas/_routes/routes";
import finPJuridicas from "../../finPJuridicas/_routes/routes";
import tabelas from "../../tabelas/_routes/routes";
import tabPaises from "../../tabPaises/_routes/routes";
import tabAtualizacoes from "../../tabAtualizacoes/_routes/routes";
import tabMoedas from "../../tabMoedas/_routes/routes";
import consultas from "../../consultas/_routes/routes";
import consEndPoints from "../../consEndPoints/_routes/routes";
import tabEstados from "../../tabEstados/_routes/routes";
import tabUnidadeLocalRFB from "../../tabUnidadeLocalRFB/_routes/routes";
import tabRecintoAduaneiro from "../../tabRecintoAduaneiro/_routes/routes";
import tabBancos from "../../tabBancos/_routes/routes";
import contabil from "../../contabil/_routes/routes";
import contCaixas from "../../contCaixas/_routes/routes";
import contUsuarios from "../../contUsuarios/_routes/routes";

export default [
  {
    name: "aplicacao",
    path: "/aplicacao",
    component: () => import("../_views/Aplicacao"),
    children: [
      ...administrador,
      ...adminUsuarios,
      ...adminGrupos,
      ...loja,
      ...lojaClientes,
      ...lojaConveniadas,
      ...lojaEmpresas,
      ...lojaCrediarios,
      ...utilitarios,
      ...utilPops,
      ...utilNotas,
      ...utilTarefas,
      ...adminLicencas,
      ...adminComponentes,
      ...adminModulos,
      ...adminPerfis,
      ...portalunico,
      ...cadastros,
      ...cadRotas,
      ...cadDespachantes,
      ...cadProprietarios,
      ...cadMotoristas,
      ...cadEmpresas,
      ...cadTransportadores,
      ...cadPermissos,
      ...cadNumeracoes,
      ...cadVeiculos,
      ...puDues,
      ...financeiro,
      ...finPFisicas,
      ...finPJuridicas,
      ...tabelas,
      ...tabPaises,
      ...tabAtualizacoes,
      ...tabMoedas,
      ...consultas,
      ...consEndPoints,
      ...tabEstados,
      ...tabUnidadeLocalRFB,
      ...tabRecintoAduaneiro,
      ...cadLocais,
      ...tabBancos,
      ...contabil,
      ...contCaixas,
      ...contUsuarios,
    ],
  },
];

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actListarPaises: async ({ commit }) => {
    try {
      const response = await axios.getPaisesListar();
      commit(types.LISTAR_PAISES, {
        paises: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

export const CRIAR_CLIENTE = "CRIAR_CLIENTE";
export const EDITAR_CLIENTE = "EDITAR_CLIENTE";
export const DELETAR_CLIENTE = "DELETAR_CLIENTE";
export const LISTAR_CLIENTES = "LISTAR_CLIENTES";
export const SELECIONAR_CLIENTE = "SELECIONAR_CLIENTE";
export const SETAR_ERRO = "SETAR_ERRO";
export const LISTAR_CLIENTECOMPRAS = "LISTAR_CLIENTECOMPRAS";
export const EDITAR_CLIENTECOMPRA = "EDITAR_CLIENTECOMPRA";
export const CRIAR_CLIENTECOMPRA = "CRIAR_CLIENTECOMPRA";
export const LISTAR_CONVENIADAS = "LISTAR_CONVENIADAS";
export const SELECIONAR_COMPRA = "SELECIONAR_COMPRA";
export const SELECT_CONVENIADAS = "SELECT_CONVENIADAS";

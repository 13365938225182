import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarNumeracao: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postNumeracao(payload);
      payload.numeracao.id = response.data.id;
      commit(types.CRIAR_NUMERACAO, { numeracao: payload.numeracao });
      var numeracao = payload.numeracao;
      dispatch("actSelecionarNumeracao", { numeracao });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarNumeracao: async ({ commit }, payload) => {
    try {
      const response = await axios.putNumeracao(payload);
      commit(types.EDITAR_NUMERACAO, {
        numeracao: JSON.parse(response.config.data)["numeracao"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarNumeracao: async ({ commit }, payload) => {
    try {
      const response = await axios.getNumeracoesSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_NUMERACAO, {
        numeracao: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarNumeracoes: async ({ commit }, licenca) => {
    try {
      const response = await axios.getNumeracoesListar(licenca);
      commit(types.LISTAR_NUMERACOES, {
        numeracoes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraNumeracao: async ({ dispatch }, payload) => {
    const numeracao = Object.assign({}, payload.numeracao);
    numeracao.lixeira = !numeracao.lixeira;
    numeracao.modificacao = payload.modificacao;
    dispatch("actEditarNumeracao", {
      numeracao: numeracao,
      licenca: payload.licenca,
    });
  },

  actResetarNumeracao: ({ commit }) => {
    commit(types.SELECIONAR_NUMERACAO, { numeracao: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

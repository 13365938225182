import * as types from "./mutation-types";

export default {
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
  [types.CAIXA_INSERT]: (state, { caixa }) => {
    state.staCaixas.push(caixa);
  },
  [types.CAIXA_UPDATE]: (state, { caixa }) => {
    const indice = state.staCaixas.findIndex((t) => t.id === caixa.id);
    state.staCaixas.splice(indice, 1, caixa);
  },
  [types.CAIXA_DELETE]: (state, { id }) => {
    const indice = state.staCaixas.findIndex((t) => t.id === id);
    state.staCaixas.splice(indice, 1);
  },
  [types.CAIXAS_LISTA]: (state, { caixas }) => {
    state.staCaixas = caixas;
  },
  [types.CAIXA_SELECIONADO]: (state, { caixa }) => {
    state.staCaixaSelecionado = caixa;
  },
  [types.PFISICAS_ATUALIZA]: (state, { pfisicas }) => {
    state.staPFisicas = pfisicas;
  },
  [types.PJURIDICAS_ATUALIZA]: (state, { pjuridicas }) => {
    state.staPJuridicas = pjuridicas;
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarDespachante: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postDespachante(payload);
      payload.despachante.id = response.data.id;
      commit(types.CRIAR_DESPACHANTE, { despachante: payload.despachante });
      dispatch("actSelecionarDespachante", {
        id: payload.despachante.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarDespachante: async ({ commit }, payload) => {
    try {
      const response = await axios.putDespachante(payload);
      commit(types.EDITAR_DESPACHANTE, {
        despachante: JSON.parse(response.config.data)["despachante"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarDespachante: async ({ commit }, payload) => {
    try {
      const response = await axios.getDespachantesSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_DESPACHANTE, {
        despachante: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarDespachantes: async ({ commit }, licenca) => {
    try {
      const response = await axios.getDespachantesListar(licenca);
      commit(types.LISTAR_DESPACHANTES, {
        despachantes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actPaisesSelect: async ({ commit }) => {
    try {
      const response = await axios.getPaisesSelect();
      commit(types.SELECT_PAISES, {
        paisesSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraDespachante: async ({ dispatch }, payload) => {
    const despachante = Object.assign({}, payload.despachante);
    despachante.lixeira = !despachante.lixeira;
    despachante.modificacao = payload.modificacao;
    dispatch("actEditarDespachante", {
      despachante: despachante,
      licenca: payload.licenca,
    });
  },

  actExcluirDespachante: async ({ dispatch }, payload) => {
    const despachante = Object.assign({}, payload.despachante);
    despachante.excluido = true;
    despachante.modificacao = payload.modificacao;
    dispatch("actEditarDespachante", {
      despachante: despachante,
      licenca: payload.licenca,
    });
  },

  actResetarDespachante: ({ commit }) => {
    commit(types.SELECIONAR_DESPACHANTE, { despachante: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

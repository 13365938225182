import * as types from "./mutation-types";

export default {
  [types.CRIAR_GRUPO]: (state, { grupo }) => {
    state.staGrupos.push(grupo);
  },
  [types.EDITAR_GRUPO]: (state, { grupo }) => {
    const indice = state.staGrupos.findIndex((t) => t.id === grupo.id);
    state.staGrupos.splice(indice, 1, grupo);
  },
  [types.DELETAR_GRUPO]: (state, { grupo }) => {
    const indice = state.staGrupos.findIndex((t) => t.id === grupo.id);
    state.staGrupos.splice(indice, 1);
  },
  [types.LISTAR_GRUPOS]: (state, { grupos }) => {
    state.staGrupos = grupos;
  },
  [types.SELECIONAR_GRUPO]: (state, { grupo }) => {
    state.staGrupoSelecionado = grupo;
  },
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },

  //
  [types.CRIAR_GRUPOSCOMPONENTES]: (state, { gruComp }) => {
    state.staGruposComponentes = gruComp;
  },
  [types.EDITAR_GRUPOSCOMPONENTES]: (state, { gruComp }) => {
    const indice = state.staGruposComponentes.findIndex(
      (t) => t.id === gruComp.id
    );
    state.staGruposComponentes.splice(indice, 1, gruComp);
  },
  [types.LISTAR_GRUPOSCOMPONENTES]: (state, { gruposComponentes }) => {
    state.staGruposComponentes = gruposComponentes;
  },
  //
};

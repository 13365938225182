import apiClient from "@/services/axios";

export default {
  postMotorista(motorista) {
    return apiClient.post("/cadMotoristas/cadastrar", motorista);
  },

  putMotorista(motorista) {
    return apiClient.put("/cadMotoristas/editar", motorista);
  },

  getMotoristasSelecionar(id, licenca) {
    return apiClient.get(`/cadMotoristas/selecionar/${id}/${licenca}`);
  },

  getMotoristasListar(licenca) {
    return apiClient.get(`/cadMotoristas/listar/${licenca}`);
  },

  getPaisesSelect() {
    return apiClient.get("/tabConsultas/listarPaises");
  },

  getObterDadosCep(cep) {
    return apiClient.get(`/consGerais/cepDados/${cep}`);
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_PERMISSO]: (state, { permisso }) => {
    state.staPermissos.push(permisso);
  },
  [types.EDITAR_PERMISSO]: (state, { permisso }) => {
    const indice = state.staPermissos.findIndex((t) => t.id === permisso.id);
    state.staPermissos.splice(indice, 1, permisso);
  },
  [types.DELETAR_PERMISSO]: (state, { permisso }) => {
    const indice = state.staPermissos.findIndex((t) => t.id === permisso.id);
    state.staPermissos.splice(indice, 1);
  },
  [types.LISTAR_PERMISSOS]: (state, { permissos }) => {
    state.staPermissos = permissos;
  },
  [types.SELECIONAR_PERMISSO]: (state, { permisso }) => {
    state.staPermissoSelect = permisso;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_TRANSPORTADORES]: (state, { transportadoresSelect }) => {
    state.staTransportadoresSelect = transportadoresSelect;
  },
};

import apiClient from "@/services/axios";

export default {
  postDue(due) {
    return apiClient.post("/puDues/cadastrar", due);
  },

  putDue(due) {
    return apiClient.put("/puDues/editar", due);
  },

  getDuesSelecionar(id, licenca) {
    return apiClient.get(`/puDues/selecionar/${id}/${licenca}`);
  },
  getDuesListar(licenca) {
    return apiClient.get(`/puDues/listar/${licenca}`);
  },
  getDadosCNPJ(cnpj) {
    return apiClient.get(`/consGerais/cnpjDados/${cnpj}`);
  },
  getMoedasSelect() {
    return apiClient.get("/tabConsultas/listarMoedas");
  },
  getUnidadeLocalRFBSelect() {
    return apiClient.get("/tabConsultas/listarUnidadeLocalRFB");
  },
  getRecintoAduaneiroSelect(unidadeRFB) {
    return apiClient.get(`/tabConsultas/listarRecintoAduaneiro/${unidadeRFB}`);
  },
  getDuesNFes(idDue, licenca) {
    return apiClient.get(`/puDuesNFes/listar/${idDue}/${licenca}`);
  },
};

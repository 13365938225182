export default {
  getUsuarios: (state) => {
    return state.staUsuarios.filter((usuario) => {
      if (!usuario.ativo) {
        return false;
      }

      const pertenceAoGrupo7 = state.staUsuariosGrupos.some((ug) => {
        return ug.idUsuario === usuario.id && ug.idGrupo === 7;
      });

      return pertenceAoGrupo7;
    });
  },

  getUsuarioCaixas: (state) => (id) => {
    return state.staUsuarioCaixas.filter(
      (usuario) => usuario.id_usuario === id
    );
  },
  getCaixasSelect: (state) => {
    return state.staUsuarioCaixas.filter(
      (usuario) => usuario.id_usuario === null
    );
  },
};

export default [
  {
    name: "FinPFisicasLista",
    path: "/FinPFisicasLista",
    component: () => import("../_views/FinPFisicasLista"),
  },
  {
    name: "FinPFisicasForm",
    path: "/FinPFisicasForm",
    component: () => import("../_components/FinPFisicasForm"),
  },
];

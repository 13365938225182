import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  criarCrediario: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postCrediario(payload);
      payload.crediario.id = response.data.id;
      commit(types.CRIAR_CREDIARIO, { crediario: payload.crediario });
      var crediario = payload.crediario;
      dispatch("actSelectCrediario", { crediario });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  editarCrediario: async ({ commit }, payload) => {
    try {
      const response = await axios.putCrediario(payload);
      commit(types.EDITAR_CREDIARIO, {
        crediario: JSON.parse(response.config.data)["crediario"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListaCredAbertos: async ({ commit }, licenca) => {
    try {
      const response = await axios.getCrediariosAbertos(licenca);
      commit(types.LISTAR_CREDIARIOS, {
        crediarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListaCredCobranca: async ({ commit }, licenca) => {
    try {
      const response = await axios.getCrediariosCobranca(licenca);
      commit(types.LISTAR_CREDIARIOS, {
        crediarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListaCredArquivados: async ({ commit }, licenca) => {
    try {
      const response = await axios.getCrediariosArquivados(licenca);
      commit(types.LISTAR_CREDIARIOS, {
        crediarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListaCredEspecifico: async ({ commit }, payload) => {
    try {
      const response = await axios.getCrediariosEspecifico(
        payload.id,
        payload.licenca
      );
      commit(types.LISTAR_CREDIARIOS, {
        crediarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actCrediarioClientesID: async ({ commit }, payload) => {
    try {
      const response = await axios.getCrediariosClienteId(
        payload.id,
        payload.licenca
      );
      commit(types.LISTAR_CREDIARIOS, {
        crediarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLimparListaCrediarios: ({ commit }) => {
    commit(types.LISTAR_CREDIARIOS, {
      crediarios: undefined,
    });
  },

  actSelectCrediario: ({ commit }, payload) => {
    commit(types.SELECIONAR_CREDIARIO, payload);
  },

  actAtivarCrediario: async ({ dispatch }, payload) => {
    const crediario = Object.assign({}, payload.crediario);
    crediario.ativo = !crediario.ativo;
    dispatch("editarCrediario", { crediario });
  },

  actResetCredSelecionado: ({ commit }) => {
    commit(types.SELECIONAR_CREDIARIO, { crediarioSelecionado: undefined });
  },

  actMudarModoListaCred: ({ commit }, { modo }) => {
    commit(types.MODOLISTA_CREDIARIO, { listaCred: modo });
  },

  actMudarIdCliente: ({ commit }, idCliente) => {
    console.log(idCliente);
    commit(types.IDCLIENTE_CREDIARIO, { idCliente: idCliente });
  },
  //

  criarCrediarioParcela: async ({ commit }, parcela) => {
    try {
      const response = await axios.postCrediarioParcela(parcela);
      commit(types.CRIAR_CREDIARIOPARCELA, { parcela: response.data });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  editarCrediarioParcela: async ({ commit }, parcela) => {
    try {
      const response = await axios.putCrediarioParcela(parcela);
      commit(types.EDITAR_CREDIARIOPARCELA, {
        crediarioParcelas: JSON.parse(response.config.data)["parcela"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarCredParcelas: async ({ commit }, payload) => {
    try {
      const response = await axios.getCrediarioParcelas(
        payload.crediario.id,
        payload.licenca
      );
      commit(types.LISTAR_CREDIARIOPARCELAS, {
        crediarioParcelas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  //

  listarConveniadas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getConveniadas(licenca);
      commit(types.LISTAR_CONVENIADAS, {
        conveniadas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelectClientes: async ({ commit }, licenca) => {
    try {
      const response = await axios.getSelectClientes(licenca);
      commit(types.SELECT_CLIENTES, {
        clientesSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelectConveniadas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getSelectConveniadas(licenca);
      commit(types.SELECT_CONVENIADAS, {
        conveniadasSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actCompletoPDF: async ({ commit }, licenca) => {
    try {
      const response = await axios.getCompletoPDF(licenca);
      commit(types.LISTAR_CREDIARIOS, {
        crediarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

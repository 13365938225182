export default [
  {
    name: "CadTransportadoresLista",
    path: "/CadTransportadoresLista",
    component: () => import("../_views/CadTransportadoresLista"),
  },
  {
    name: "CadTransportadoresForm",
    path: "/CadTransportadoresForm",
    component: () => import("../_components/CadTransportadoresForm"),
  },
];

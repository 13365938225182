export default [
  {
    name: "AdminGruposLista",
    path: "/AdminGruposLista",
    component: () => import("../_views/AdminGruposLista"),
  },
  {
    name: "AdminGruposForm",
    path: "/AdminGruposForm",
    component: () => import("../_components/AdminGruposForm"),
  },
  {
    name: "AdminGruposCompForm",
    path: "/AdminGruposCompForm",
    component: () => import("../_components/AdminGruposCompForm"),
  },
  {
    name: "AdminGruposCompLista",
    path: "/AdminGruposCompLista",
    component: () => import("../_components/AdminGruposCompLista"),
  },
];

import Vue from "vue";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);

export default {
  components: {
    Vuelidate,
  },
};

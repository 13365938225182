import apiClient from "@/services/axios";

export default {
  // postUsuarios(payload) {
  //   return apiClient.post("/contUsuarios/insert", payload);
  // },

  // putUsuarios(payload) {
  //   return apiClient.put("/contUsuarios/update", payload);
  // },

  // deleteCaixa(id, licenca) {
  //   return apiClient.delete(`/contUsuarios/delete/${id}/${licenca}`);
  // },

  // getUsuariosLista(licenca) {
  //   return apiClient.get(`/contUsuarios/lista/${licenca}`);
  // },

  getUsuarioSelecionado(id, licenca) {
    return apiClient.get(`/adminUsuarios/selecionar/${id}/${licenca}`);
  },

  getUsuariosLista(licenca) {
    return apiClient.get(`/adminUsuarios/listar/${licenca}`);
  },

  getUsuariosGrupos(licenca) {
    return apiClient.get(`/adminUsuariosGrupos/grupos/${licenca}`);
  },

  getUsuarioCaixas(licenca) {
    return apiClient.get(`/contCaixas/usuariocaixas/${licenca}`);
  },

  putCaixas(payload) {
    return apiClient.put("/contCaixas/update", payload);
  },
};

import Vue from "vue";

import VueMask from "v-mask";

Vue.use(VueMask);

export default {
  components: {
    VueMask,
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarEmpresa: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postEmpresa(payload);
      payload.empresa.id = response.data.id;
      commit(types.CRIAR_EMPRESA, { empresa: payload.empresa });
      dispatch("actSelecionarEmpresa", {
        id: payload.empresa.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarEmpresa: async ({ commit }, payload) => {
    try {
      const response = await axios.putEmpresa(payload);
      commit(types.EDITAR_EMPRESA, {
        empresa: JSON.parse(response.config.data)["empresa"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarEmpresa: async ({ commit }, payload) => {
    try {
      const response = await axios.getEmpresasSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_EMPRESA, {
        empresa: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarEmpresas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getEmpresasListar(licenca);
      commit(types.LISTAR_EMPRESAS, {
        empresas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actPaisesSelect: async ({ commit }) => {
    try {
      const response = await axios.getPaisesSelect();
      commit(types.SELECT_PAISES, {
        paisesSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraEmpresa: async ({ dispatch }, payload) => {
    const empresa = Object.assign({}, payload.empresa);
    empresa.lixeira = !empresa.lixeira;
    empresa.modificacao = payload.modificacao;
    dispatch("actEditarEmpresa", {
      empresa: empresa,
      licenca: payload.licenca,
    });
  },

  actExcluirEmpresa: async ({ dispatch }, payload) => {
    const empresa = Object.assign({}, payload.empresa);
    empresa.excluido = true;
    empresa.modificacao = payload.modificacao;
    dispatch("actEditarEmpresa", {
      empresa: empresa,
      licenca: payload.licenca,
    });
  },

  actResetarEmpresa: ({ commit }) => {
    commit(types.SELECIONAR_EMPRESA, { empresa: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCaixaInsert: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postCaixas(payload);
      payload.caixa.id = response.data.id;
      commit(types.CAIXA_INSERT, { caixa: payload.caixa });
      dispatch("actCaixaSelecionado", {
        id: payload.caixa.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actCaixaUpdate: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.putCaixas(payload);
      commit(types.CAIXA_UPDATE, {
        caixa: JSON.parse(response.config.data)["caixa"],
      });
      dispatch("actCaixaSelecionado", {
        id: payload.caixa.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actCaixaDelete: async ({ commit }, payload) => {
    try {
      await axios.deleteCaixa(payload.id, payload.licenca);
      commit(types.CAIXA_DELETE, { id: payload.id });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actCaixasLista: async ({ commit }, payload) => {
    try {
      const response = await axios.getCaixasLista(payload.licenca);
      commit(types.CAIXAS_LISTA, {
        caixas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actCaixaSelecionado: async ({ commit }, payload) => {
    try {
      const response = await axios.getCaixaSelecionado(
        payload.id,
        payload.licenca
      );
      commit(types.CAIXA_SELECIONADO, {
        caixa: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actCaixaLixeira: async ({ dispatch }, payload) => {
    const caixa = Object.assign({}, payload.caixa);
    caixa.lixeira = !caixa.lixeira;
    caixa.modificacao = payload.modificacao;
    dispatch("actCaixaUpdate", {
      caixa: caixa,
      licenca: payload.licenca,
    });
  },

  actCaixaResetar: ({ commit }) => {
    commit(types.CAIXA_SELECIONADO, { caixa: {} });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },

  actAtualizaPFisicas: async ({ commit }, payload) => {
    try {
      const response = await axios.getAtualizaPFisicas(payload.licenca);
      commit(types.PFISICAS_ATUALIZA, {
        pfisicas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actAtualizaPJuridicas: async ({ commit }, payload) => {
    try {
      const response = await axios.getAtualizaPJuridicas(payload.licenca);
      commit(types.PJURIDICAS_ATUALIZA, {
        pjuridicas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_POP]: (state, { pop }) => {
    state.pops.push(pop);
  },
  [types.EDITAR_POP]: (state, { pop }) => {
    const indice = state.pops.findIndex((t) => t.id === pop.id);
    state.pops.splice(indice, 1, pop);
  },
  [types.DELETAR_POP]: (state, { pop }) => {
    const indice = state.pops.findIndex((t) => t.id === pop.id);
    state.pops.splice(indice, 1);
  },
  [types.LISTAR_POPS]: (state, { pops }) => {
    state.pops = pops;
  },
  [types.SELECIONAR_POP]: (state, { pop }) => {
    state.popSelecionado = pop;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
  [types.LISTAR_USUARIOS]: (state, { listaUsuarios }) => {
    state.listaUsuarios = listaUsuarios;
  },
};

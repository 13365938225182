import apiClient from "@/services/axios";

export default {
  postProprietario(proprietario) {
    return apiClient.post("/cadProprietarios/cadastrar", proprietario);
  },

  putProprietario(proprietario) {
    return apiClient.put("/cadProprietarios/editar", proprietario);
  },

  getProprietariosSelecionar(id, licenca) {
    return apiClient.get(`/cadProprietarios/selecionar/${id}/${licenca}`);
  },

  getProprietariosListar(licenca) {
    return apiClient.get(`/cadProprietarios/listar/${licenca}`);
  },

  getPaisesSelect() {
    return apiClient.get("/tabConsultas/listarPaises");
  },

  getObterDadosCep(cep) {
    return apiClient.get(`/consGerais/cepDados/${cep}`);
  },
};

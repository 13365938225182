export default [
  {
    name: "UtilPopsLista",
    path: "/UtilPopsLista",
    component: () => import("../_views/UtilPopsLista"),
  },
  {
    name: "UtilPopsForm",
    path: "/UtilPopsForm",
    component: () => import("../_components/UtilPopsForm"),
  },
];

import apiClient from "@/services/axios";

export default {
  postComponente(componente) {
    return apiClient.post("/adminComponentes/cadastrar", componente);
  },

  putComponente(componente) {
    return apiClient.put("/adminComponentes/editar", componente);
  },

  getComponentes() {
    return apiClient.get("/adminComponentes/listar");
  },
};

import { store as login } from "../resources/login";
import { store as adminUsuarios } from "./../resources/adminUsuarios";
import { store as adminGrupos } from "./../resources/adminGrupos";
import { store as lojaClientes } from "./../resources/lojaClientes";
import { store as utilPops } from "../resources/utilPops";
import { store as utilTarefas } from "./../resources/utilTarefas";
import { store as lojaConveniadas } from "./../resources/lojaConveniadas";
import { store as lojaEmpresas } from "./../resources/lojaEmpresas";
import { store as lojaCrediarios } from "./../resources/lojaCrediarios";
import { store as adminLicencas } from "./../resources/adminLicencas";
import { store as adminComponentes } from "./../resources/adminComponentes";
import { store as adminModulos } from "./../resources/adminModulos";
import { store as adminPerfis } from "./../resources/adminPerfis";
import { store as cadRotas } from "./../resources/cadRotas";
import { store as cadDespachantes } from "./../resources/cadDespachantes";
import { store as cadProprietarios } from "./../resources/cadProprietarios";
import { store as cadMotoristas } from "./../resources/cadMotoristas";
import { store as cadEmpresas } from "./../resources/cadEmpresas";
import { store as cadTransportadores } from "./../resources/cadTransportadores";
import { store as cadPermissos } from "./../resources/cadPermissos";
import { store as cadNumeracoes } from "./../resources/cadNumeracoes";
import { store as cadVeiculos } from "./../resources/cadVeiculos";
import { store as puDues } from "./../resources/puDues";
import { store as finPFisicas } from "./../resources/finPFisicas";
import { store as finPJuridicas } from "./../resources/finPJuridicas";
import { store as tabPaises } from "./../resources/tabPaises";
import { store as consEndPoints } from "../resources/consEndPoints";
import { store as utilNotas } from "../resources/utilNotas";
import { store as tabEstados } from "./../resources/tabEstados";
import { store as tabUnidadeLocalRFB } from "./../resources/tabUnidadeLocalRFB";
import { store as tabRecintoAduaneiro } from "./../resources/tabRecintoAduaneiro";
import { store as tabBancos } from "./../resources/tabBancos";
import { store as puDuesNFes } from "./../resources/puDues";
import { store as contCaixas } from "../resources/contCaixas";
import { store as contUsuarios } from "../resources/contUsuarios";

export default {
  login,
  adminUsuarios,
  adminGrupos,
  lojaClientes,
  utilPops,
  utilTarefas,
  lojaConveniadas,
  lojaEmpresas,
  lojaCrediarios,
  adminLicencas,
  adminComponentes,
  adminModulos,
  adminPerfis,
  cadRotas,
  cadDespachantes,
  cadProprietarios,
  cadMotoristas,
  cadEmpresas,
  cadTransportadores,
  cadPermissos,
  cadNumeracoes,
  cadVeiculos,
  puDues,
  finPFisicas,
  finPJuridicas,
  tabPaises,
  consEndPoints,
  utilNotas,
  tabEstados,
  tabUnidadeLocalRFB,
  tabRecintoAduaneiro,
  tabBancos,
  puDuesNFes,
  contCaixas,
  contUsuarios,
};

import * as types from "./mutation-types";

export default {
  [types.LISTAR_PAISES]: (state, { paises }) => {
    state.staPaises = paises;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};

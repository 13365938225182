import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarModulo: async ({ commit, dispatch }, payload) => {
    try {
      // console.log("payload", payload);
      const response = await axios.postModulo(payload);
      payload.modulo.id = response.data.id;
      commit(types.CRIAR_MODULO, { modulo: payload.modulo });
      var modulo = payload.modulo;
      dispatch("actSelecionarModulo", { modulo });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarModulo: async ({ commit }, payload) => {
    try {
      const response = await axios.putModulo(payload);
      commit(types.EDITAR_MODULO, {
        modulo: JSON.parse(response.config.data)["modulo"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarModulos: async ({ commit }) => {
    try {
      const response = await axios.getModulos();
      commit(types.LISTAR_MODULOS, {
        modulos: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarModulo: ({ commit }, payload) => {
    commit(types.SELECIONAR_MODULO, payload);
  },

  actAtivarModulo: async ({ dispatch }, payload) => {
    const modulo = Object.assign({}, payload.modulo);
    modulo.ativo = !modulo.ativo;
    dispatch("actEditarModulo", { modulo });
  },

  resetarModulo: ({ commit }) => {
    commit(types.SELECIONAR_MODULO, { modulo: undefined });
  },

  //

  actEditarModulosComponentes: async ({ commit }, payload) => {
    try {
      const response = await axios.putModulosComponentes(payload);
      commit(types.EDITAR_MODULOSCOMPONENTES, {
        modComp: JSON.parse(response.config.data)["modComp"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarModulosComponentes: async ({ commit }, payload) => {
    try {
      const response = await axios.getModulosComponentes(payload.modulo.id);
      commit(types.LISTAR_MODULOSCOMPONENTES, {
        modulosComponentes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarModulosComponentes: ({ commit }) => {
    var res = [];
    commit(types.LISTAR_MODULOSCOMPONENTES, { modulosComponentes: res });
  },

  actAtivarModComp: async ({ dispatch }, payload) => {
    const modComp = Object.assign({}, payload.modComp);
    modComp.ativo = !modComp.ativo;
    dispatch("actEditarModulosComponentes", { modComp });
  },

  actSyncModComp: async ({ commit }, payload) => {
    var response = undefined;
    try {
      if (payload.modulo == "todos") {
        response = await axios.getSyncModComp(payload.modulo);
      } else {
        response = await axios.getSyncModComp(payload.modulo.id);
      }
      commit(types.LISTAR_MODULOSCOMPONENTES, {
        modulosComponentes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

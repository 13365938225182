import * as types from "./mutation-types";

export default {
  [types.CRIAR_CREDIARIO]: (state, { crediario }) => {
    state.crediarios.push(crediario);
  },
  [types.EDITAR_CREDIARIO]: (state, { crediario }) => {
    const indice = state.crediarios.findIndex((t) => t.id === crediario.id);
    state.crediarios.splice(indice, 1, crediario);
  },
  [types.DELETAR_CREDIARIO]: (state, { crediario }) => {
    const indice = state.crediarios.findIndex((t) => t.id === crediario.id);
    state.crediarios.splice(indice, 1);
  },
  [types.LISTAR_CREDIARIOS]: (state, { crediarios }) => {
    state.crediarios = crediarios;
  },
  [types.SELECIONAR_CREDIARIO]: (state, { crediario }) => {
    state.crediarioSelecionado = crediario;
  },
  //
  [types.CRIAR_CREDIARIOPARCELA]: (state, { parcela }) => {
    state.crediarioParcelas = parcela;
  },
  [types.EDITAR_CREDIARIOPARCELA]: (state, { parcela }) => {
    state.crediarioParcelas = parcela;
  },
  [types.LISTAR_CREDIARIOPARCELAS]: (state, { crediarioParcelas }) => {
    state.crediarioParcelas = crediarioParcelas;
  },
  //
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
  [types.LISTAR_CONVENIADAS]: (state, { conveniadas }) => {
    state.conveniadas = conveniadas;
  },
  [types.SELECT_CLIENTES]: (state, { clientesSelect }) => {
    state.clientesSelect = clientesSelect;
  },
  [types.SELECT_CONVENIADAS]: (state, { conveniadasSelect }) => {
    state.conveniadasSelect = conveniadasSelect;
  },
  [types.MODOLISTA_CREDIARIO]: (state, { listaCred }) => {
    state.staListaCred = listaCred;
  },
  [types.IDCLIENTE_CREDIARIO]: (state, { idCliente }) => {
    state.staIdCliente = idCliente;
  },
};

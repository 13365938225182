export default [
  {
    name: "CadRotasLista",
    path: "/CadRotasLista",
    component: () => import("../_views/CadRotasLista"),
  },
  {
    name: "CadRotasForm",
    path: "/CadRotasForm",
    component: () => import("../_components/CadRotasForm"),
  },
];

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  criarEmpresa: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postEmpresa(payload);
      payload.empresa.id = response.data.id;
      commit(types.CRIAR_EMPRESA, { empresa: payload.empresa });
      var empresa = payload.empresa;
      dispatch("selecionarEmpresa", { empresa });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  editarEmpresa: async ({ commit }, payload) => {
    try {
      const response = await axios.putEmpresa(payload);
      commit(types.EDITAR_EMPRESA, {
        empresa: JSON.parse(response.config.data)["empresa"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  listarEmpresas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getEmpresas(licenca);
      commit(types.LISTAR_EMPRESAS, {
        empresas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  selecionarEmpresa: ({ commit }, payload) => {
    commit(types.SELECIONAR_EMPRESA, payload);
  },

  resetarEmpresa: ({ commit }) => {
    commit(types.SELECIONAR_EMPRESA, { empresa: undefined });
  },

  resetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

export default [
  {
    name: "LojaEmpresasLista",
    path: "/LojaEmpresasLista",
    component: () => import("../_views/LojaEmpresasLista"),
  },
  {
    name: "LojaEmpresasForm",
    path: "/LojaEmpresasForm",
    component: () => import("../_components/LojaEmpresasForm"),
  },
];

import * as types from "./mutation-types";

export default {
  [types.CRIAR_LICENCA]: (state, { licenca }) => {
    state.staLicencas.push(licenca);
  },
  [types.EDITAR_LICENCA]: (state, { licenca }) => {
    const indice = state.staLicencas.findIndex((t) => t.id === licenca.id);
    state.staLicencas.splice(indice, 1, licenca);
  },
  [types.LISTAR_LICENCAS]: (state, { licencas }) => {
    state.staLicencas = licencas;
  },
  [types.SELECIONAR_LICENCA]: (state, { licenca }) => {
    state.staLicencaSelecionado = licenca;
  },
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
  [types.SELECT_PERFIS]: (state, { perfisSelect }) => {
    state.staPerfisSelect = perfisSelect;
  },
};

export default [
  {
    name: "CadNumeracoesLista",
    path: "/CadNumeracoesLista",
    component: () => import("../_views/CadNumeracoesLista"),
  },
  {
    name: "CadNumeracoesForm",
    path: "/CadNumeracoesForm",
    component: () => import("../_components/CadNumeracoesForm"),
  },
];

import * as types from "./mutation-types";

export default {
  [types.SET_USER](state, payload) {
    state.usuario = payload;
  },
  [types.SET_TOKEN](state, payload) {
    state.token = payload;
  },
  [types.SET_LICENCA](state, payload) {
    state.licenca = payload;
  },
  [types.SET_PERMISSOES](state, payload) {
    state.staPermissoes = payload;
  },
};

export default [
  {
    name: "LojaCrediariosLista",
    path: "/LojaCrediariosLista",
    component: () => import("../_views/LojaCrediariosLista"),
  },
  {
    name: "LojaCrediariosParcelasForm",
    path: "/LojaCrediariosParcelasForm",
    component: () => import("../_components/LojaCrediariosParcelasForm"),
  },
  {
    name: "LojaCrediariosParcelasLista",
    path: "/LojaCrediariosParcelasLista",
    component: () => import("../_components/LojaCrediariosParcelasLista"),
  },
];

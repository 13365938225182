export const CRIAR_PERFIL = "CRIAR_PERFIL";
export const EDITAR_PERFIL = "EDITAR_PERFIL";
export const LISTAR_PERFIS = "LISTAR_PERFIS";
export const SELECIONAR_PERFIL = "SELECIONAR_PERFIL";
export const SETAR_ERRO = "SETAR_ERRO";
export const CRIAR_PERFISMODULOS = "CRIAR_PERFISMODULOS";
export const EDITAR_PERFISMODULOS = "EDITAR_PERFISMODULOS";
export const DELETAR_PERFISMODULOS = "DELETAR_PERFISMODULOS";
export const LISTAR_PERFISMODULOS = "LISTAR_PERFISMODULOS";
export const SELECT_MODULOS = "SELECT_MODULOS";

import * as types from "./mutation-types";

export default {
  [types.CRIAR_DESPACHANTE]: (state, { despachante }) => {
    state.staDespachantes.push(despachante);
  },
  [types.EDITAR_DESPACHANTE]: (state, { despachante }) => {
    const indice = state.staDespachantes.findIndex(
      (t) => t.id === despachante.id
    );
    state.staDespachantes.splice(indice, 1, despachante);
  },
  [types.DELETAR_DESPACHANTE]: (state, { despachante }) => {
    const indice = state.staDespachantes.findIndex(
      (t) => t.id === despachante.id
    );
    state.staDespachantes.splice(indice, 1);
  },
  [types.LISTAR_DESPACHANTES]: (state, { despachantes }) => {
    state.staDespachantes = despachantes;
  },
  [types.SELECIONAR_DESPACHANTE]: (state, { despachante }) => {
    state.staDespachanteSelect = despachante;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_PAISES]: (state, { paisesSelect }) => {
    state.staPaisesSelect = paisesSelect;
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  // actUsuariosInsert: async ({ commit, dispatch }, payload) => {
  //   try {
  //     const response = await axios.postUsuarios(payload);
  //     payload.usuario.id = response.data.id;
  //     commit(types.USUARIO_INSERT, { usuario: payload.usuario });
  //     dispatch("actUsuariosSelecionado", {
  //       id: payload.usuario.id,
  //       licenca: payload.licenca,
  //     });
  //   } catch (erro) {
  //     commit(types.SETAR_ERRO, { erro });
  //   }
  // },

  // actUsuariosUpdate: async ({ commit, dispatch }, payload) => {
  //   try {
  //     const response = await axios.putUsuarios(payload);
  //     commit(types.USUARIO_UPDATE, {
  //       usuario: JSON.parse(response.config.data)["usuario"],
  //     });
  //     dispatch("actUsuariosSelecionado", {
  //       id: payload.usuario.id,
  //       licenca: payload.licenca,
  //     });
  //   } catch (erro) {
  //     commit(types.SETAR_ERRO, { erro });
  //   }
  // },

  // actUsuariosDelete: async ({ commit }, payload) => {
  //   try {
  //     await axios.deleteUsuarios(payload.id, payload.licenca);
  //     commit(types.USUARIO_DELETE, { id: payload.id });
  //   } catch (erro) {
  //     commit(types.SETAR_ERRO, { erro });
  //   }
  // },

  // actUsuariosLista: async ({ commit }, payload) => {
  //   try {
  //     const response = await axios.getUsuariosLista(payload.licenca);
  //     commit(types.USUARIOS_LISTA, {
  //       usuarios: response.data.response,
  //     });
  //   } catch (erro) {
  //     commit(types.SETAR_ERRO, { erro });
  //   }
  // },

  actUsuarioSelecionado: async ({ commit }, payload) => {
    try {
      const response = await axios.getUsuarioSelecionado(
        payload.id,
        payload.licenca
      );
      commit(types.USUARIO_SELECIONADO, {
        usuario: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actUsuariosResetar: ({ commit }) => {
    commit(types.USUARIO_SELECIONADO, { usuario: {} });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },

  actUsuariosLista: async ({ commit }, payload) => {
    try {
      const response = await axios.getUsuariosLista(payload.licenca);
      commit(types.USUARIOS_LISTA, {
        usuarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actUsuariosGrupos: async ({ commit }, payload) => {
    try {
      const response = await axios.getUsuariosGrupos(payload.licenca);
      commit(types.USUARIOS_GRUPOS, {
        usuariosGrupos: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actUsuarioCaixas: async ({ commit }, payload) => {
    try {
      const response = await axios.getUsuarioCaixas(payload.licenca);
      commit(types.USUARIO_CAIXAS, {
        usuarioCaixas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actCaixaUpdate: async ({ commit }, payload) => {
    try {
      const response = await axios.putCaixas(payload);
      commit(types.USUARIO_CAIXA_UPDATE, {
        caixa: JSON.parse(response.config.data)["caixa"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

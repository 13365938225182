import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarPFisica: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postPFisica(payload);
      payload.pfisica.id = response.data.id;
      commit(types.CRIAR_PFISICA, { pfisica: payload.pfisica });
      dispatch("actSelecionarPFisica", {
        id: payload.pfisica.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarPFisica: async ({ commit }, payload) => {
    try {
      const response = await axios.putPFisica(payload);
      commit(types.EDITAR_PFISICA, {
        pfisica: JSON.parse(response.config.data)["pfisica"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarPFisica: async ({ commit }, payload) => {
    try {
      const response = await axios.getPFisicasSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_PFISICA, {
        pfisica: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarPFisicas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getPFisicasListar(licenca);
      commit(types.LISTAR_PFISICAS, {
        pfisicas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actPaisesSelect: async ({ commit }) => {
    try {
      const response = await axios.getPaisesSelect();
      commit(types.SELECT_PAISES, {
        paisesSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraPFisica: async ({ dispatch }, payload) => {
    const pfisica = Object.assign({}, payload.pfisica);
    pfisica.lixeira = !pfisica.lixeira;
    pfisica.modificacao = payload.modificacao;
    dispatch("actEditarPFisica", {
      pfisica: pfisica,
      licenca: payload.licenca,
    });
  },

  actResetarPFisica: ({ commit }) => {
    commit(types.SELECIONAR_PFISICA, { pfisica: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

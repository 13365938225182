import * as types from "./mutation-types";

export default {
  [types.CRIAR_ROTA]: (state, { rota }) => {
    state.staRotas.push(rota);
  },
  [types.EDITAR_ROTA]: (state, { rota }) => {
    const indice = state.staRotas.findIndex((t) => t.id === rota.id);
    state.staRotas.splice(indice, 1, rota);
  },
  [types.DELETAR_ROTA]: (state, { rota }) => {
    const indice = state.staRotas.findIndex((t) => t.id === rota.id);
    state.staRotas.splice(indice, 1);
  },
  [types.LISTAR_ROTAS]: (state, { rotas }) => {
    state.staRotas = rotas;
  },
  [types.SELECIONAR_ROTA]: (state, { rota }) => {
    state.staRotaSelect = rota;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};

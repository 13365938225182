import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarPerfil: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postPerfil(payload);
      payload.perfil.id = response.data.id;
      commit(types.CRIAR_PERFIL, { perfil: payload.perfil });
      var perfil = payload.perfil;
      dispatch("selecionarPerfil", { perfil });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarPerfil: async ({ commit }, payload) => {
    try {
      const response = await axios.putPerfil(payload);
      commit(types.EDITAR_PERFIL, {
        perfil: JSON.parse(response.config.data)["perfil"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarPerfis: async ({ commit }) => {
    try {
      const response = await axios.getPerfis();
      commit(types.LISTAR_PERFIS, {
        perfis: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarPerfil: ({ commit }, payload) => {
    commit(types.SELECIONAR_PERFIL, payload);
  },

  actAtivarPerfil: async ({ dispatch }, payload) => {
    const perfil = Object.assign({}, payload.perfil);
    perfil.ativo = !perfil.ativo;
    dispatch("actEditarPerfil", { perfil });
  },

  actResetarPerfil: ({ commit }) => {
    commit(types.SELECIONAR_PERFIL, { perfil: undefined });
  },

  //

  actCriarPerfisModulos: async ({ commit }, payload) => {
    try {
      const response = await axios.postPerfisModulos(payload);
      payload.perfMod.id = response.data.id;
      commit(types.CRIAR_PERFISMODULOS, { perfMod: payload.perfMod });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarPerfisModulos: async ({ commit }, payload) => {
    try {
      const response = await axios.putPerfisModulos(payload);
      commit(types.EDITAR_PERFISMODULOS, {
        perfMod: JSON.parse(response.config.data)["perfMod"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actDeletarPerfisModulos: async ({ commit }, payload) => {
    try {
      // console.log("*", payload.perfMod);
      const response = await axios.deletePerfisModulos(payload.perfMod.id);
      console.log("&", response);
      commit(types.DELETAR_PERFISMODULOS, {
        perfMod: payload.perfMod,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarPerfisModulos: async ({ commit }, payload) => {
    try {
      const response = await axios.getPerfisModulos(payload.perfil.id);
      commit(types.LISTAR_PERFISMODULOS, {
        perfisModulos: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarPerfisModulos: ({ commit }) => {
    var res = [];
    commit(types.LISTAR_PERFISMODULOS, { perfisModulos: res });
  },

  actAtivarPerfMod: async ({ dispatch }, payload) => {
    const perfMod = Object.assign({}, payload.perfMod);
    perfMod.ativo = !perfMod.ativo;
    dispatch("actEditarPerfisModulos", { perfMod });
  },

  actSelectModulos: async ({ commit }) => {
    try {
      const response = await axios.getSelectModulos();
      commit(types.SELECT_MODULOS, {
        modulosSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

export default [
  {
    name: "PuDuesLista",
    path: "/PuDuesLista",
    component: () => import("../_views/PuDuesLista"),
  },
  {
    name: "PuDuesForm",
    path: "/PuDuesForm",
    component: () => import("../_components/PuDuesForm"),
  },
];

export default [
  {
    name: "CadDespachantesLista",
    path: "/CadDespachantesLista",
    component: () => import("../_views/CadDespachantesLista"),
  },
  {
    name: "CadDespachantesForm",
    path: "/CadDespachantesForm",
    component: () => import("../_components/CadDespachantesForm"),
  },
];

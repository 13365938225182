import * as types from "./mutation-types";

export default {
  [types.CRIAR_EMPRESA]: (state, { empresa }) => {
    state.staEmpresas.push(empresa);
  },
  [types.EDITAR_EMPRESA]: (state, { empresa }) => {
    const indice = state.staEmpresas.findIndex((t) => t.id === empresa.id);
    state.staEmpresas.splice(indice, 1, empresa);
  },
  [types.DELETAR_EMPRESA]: (state, { empresa }) => {
    const indice = state.staEmpresas.findIndex((t) => t.id === empresa.id);
    state.staEmpresas.splice(indice, 1);
  },
  [types.LISTAR_EMPRESAS]: (state, { empresas }) => {
    state.staEmpresas = empresas;
  },
  [types.SELECIONAR_EMPRESA]: (state, { empresa }) => {
    state.staEmpresaSelect = empresa;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_PAISES]: (state, { paisesSelect }) => {
    state.staPaisesSelect = paisesSelect;
  },
};

export default [
  {
    name: "AdminComponentesLista",
    path: "/AdminComponentesLista",
    component: () => import("../_views/AdminComponentesLista"),
  },
  {
    name: "AdminComponentesForm",
    path: "/AdminComponentesForm",
    component: () => import("../_components/AdminComponentesForm"),
  },
];

import apiClient from "@/services/axios";

export default {
  postTransportador(transportador) {
    return apiClient.post("/cadTransportadores/cadastrar", transportador);
  },

  putTransportador(transportador) {
    return apiClient.put("/cadTransportadores/editar", transportador);
  },

  getTransportadoresSelecionar(id, licenca) {
    return apiClient.get(`/cadTransportadores/selecionar/${id}/${licenca}`);
  },

  getTransportadoresListar(licenca) {
    return apiClient.get(`/cadTransportadores/listar/${licenca}`);
  },

  getPaisesSelect() {
    return apiClient.get("/tabConsultas/listarPaises");
  },

  getObterDadosCep(cep) {
    return apiClient.get(`/consGerais/cepDados/${cep}`);
  },
};

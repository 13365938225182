export const CRIAR_CREDIARIO = "CRIAR_CREDIARIO";
export const EDITAR_CREDIARIO = "EDITAR_CREDIARIO";
export const DELETAR_CREDIARIO = "DELETAR_CREDIARIO";
export const LISTAR_CREDIARIOS = "LISTAR_CREDIARIOS";
export const SELECIONAR_CREDIARIO = "SELECIONAR_CREDIARIO";
export const SETAR_ERRO = "SETAR_ERRO";
export const LISTAR_CREDIARIOPARCELAS = "LISTAR_CREDIARIOPARCELAS";
export const EDITAR_CREDIARIOPARCELA = "EDITAR_CREDIARIOPARCELA";
export const CRIAR_CREDIARIOPARCELA = "CRIAR_CREDIARIOPARCELA";
export const LISTAR_CONVENIADAS = "LISTAR_CONVENIADAS";
export const SELECT_CLIENTES = "SELECT_CLIENTES";
export const SELECT_CONVENIADAS = "SELECT_CONVENIADAS";
export const MODOLISTA_CREDIARIO = "MODOLISTA_CREDIARIO";
export const IDCLIENTE_CREDIARIO = "IDCLIENTE_CREDIARIO";

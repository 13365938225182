export default {
  getDespDespachantes: (state) =>
    state.staDespachantes.filter(
      (d) => !d.lixeira && !d.excluido && d.tipo == "despachante"
    ),
  getDespAjudantes: (state) =>
    state.staDespachantes.filter(
      (d) => !d.lixeira && !d.excluido && d.tipo == "ajudante"
    ),
  getDespLixeira: (state) =>
    state.staDespachantes.filter((d) => d.lixeira && !d.excluido),
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_EMPRESA]: (state, { empresa }) => {
    state.empresas.push(empresa);
  },
  [types.EDITAR_EMPRESA]: (state, { empresa }) => {
    const indice = state.empresas.findIndex((t) => t.id === empresa.id);
    state.empresas.splice(indice, 1, empresa);
  },
  [types.DELETAR_EMPRESA]: (state, { empresa }) => {
    const indice = state.empresas.findIndex((t) => t.id === empresa.id);
    state.empresas.splice(indice, 1);
  },
  [types.LISTAR_EMPRESAS]: (state, { empresas }) => {
    state.empresas = empresas;
  },
  [types.SELECIONAR_EMPRESA]: (state, { empresa }) => {
    state.empresaSelect = empresa;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_MOTORISTA]: (state, { motorista }) => {
    state.staMotoristas.push(motorista);
  },
  [types.EDITAR_MOTORISTA]: (state, { motorista }) => {
    const indice = state.staMotoristas.findIndex((t) => t.id === motorista.id);
    state.staMotoristas.splice(indice, 1, motorista);
  },
  [types.DELETAR_MOTORISTA]: (state, { motorista }) => {
    const indice = state.staMotoristas.findIndex((t) => t.id === motorista.id);
    state.staMotoristas.splice(indice, 1);
  },
  [types.LISTAR_MOTORISTAS]: (state, { motoristas }) => {
    state.staMotoristas = motoristas;
  },
  [types.SELECIONAR_MOTORISTA]: (state, { motorista }) => {
    state.staMotoristaSelect = motorista;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_PAISES]: (state, { paisesSelect }) => {
    state.staPaisesSelect = paisesSelect;
  },
};

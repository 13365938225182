import apiClient from "@/services/axios";

export default {
  postPerfil(prefil) {
    return apiClient.post("/adminPerfis/cadastrar", prefil);
  },

  putPerfil(prefil) {
    return apiClient.put("/adminPerfis/editar", prefil);
  },

  getPerfis() {
    return apiClient.get("/adminPerfis/listar");
  },

  //

  postPerfisModulos(perfMod) {
    return apiClient.post("/adminPerfisModulos/cadastrar", perfMod);
  },

  putPerfisModulos(perfMod) {
    return apiClient.put("/adminPerfisModulos/editar", perfMod);
  },

  deletePerfisModulos(idPerfMod) {
    return apiClient.delete(`/adminPerfisModulos/excluir/${idPerfMod}`);
  },

  getPerfisModulos(idPerfil) {
    return apiClient.get(`/adminPerfisModulos/listar/${idPerfil}`);
  },

  //

  getSelectModulos() {
    return apiClient.get(`/adminModulos/select`);
  },
};

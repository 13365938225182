import * as types from "./mutation-types";

export default {
  [types.CRIAR_PJURIDICA]: (state, { pjuridica }) => {
    state.staPJuridicas.push(pjuridica);
  },
  [types.EDITAR_PJURIDICA]: (state, { pjuridica }) => {
    const indice = state.staPJuridicas.findIndex((t) => t.id === pjuridica.id);
    state.staPJuridicas.splice(indice, 1, pjuridica);
  },
  [types.DELETAR_PJURIDICA]: (state, { pjuridica }) => {
    const indice = state.staPJuridicas.findIndex((t) => t.id === pjuridica.id);
    state.staPJuridicas.splice(indice, 1);
  },
  [types.LISTAR_PJURIDICAS]: (state, { pjuridicas }) => {
    state.staPJuridicas = pjuridicas;
  },
  [types.SELECIONAR_PJURIDICA]: (state, { pjuridica }) => {
    state.staPJuridicaSelect = pjuridica;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_PAISES]: (state, { paisesSelect }) => {
    state.staPaisesSelect = paisesSelect;
  },
};

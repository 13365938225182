import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actListarRecintoAduaneiro: async ({ commit }) => {
    try {
      const response = await axios.getRecintoAduaneiroListar();
      commit(types.LISTAR_RECINTOADUANEIRO, {
        recintoaduaneiro: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

export const CRIAR_DUE = "CRIAR_DUE";
export const EDITAR_DUE = "EDITAR_DUE";
export const DELETAR_DUE = "DELETAR_DUE";
export const LISTAR_DUES = "LISTAR_DUES";
export const SELECIONAR_DUE = "SELECIONAR_DUE";
export const OBTER_DADOSCNPJ = "OBTER_DADOSCNPJ";
export const SELECT_MOEDAS = "SELECT_MOEDAS";
export const SELECT_UNIDADELOCALRFB = "SELECT_UNIDADELOCALRFB";
export const SELECT_RECADUANDESP = "SELECT_RECADUANDESP";
export const SELECT_RECADUANEMB = "SELECT_RECADUANEMB";
export const LISTAR_DUENFS = "LISTAR_DUENFS";
export const SETAR_ERRO = "SETAR_ERRO";

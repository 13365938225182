import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarMotorista: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postMotorista(payload);
      payload.motorista.id = response.data.id;
      commit(types.CRIAR_MOTORISTA, { motorista: payload.motorista });
      dispatch("actSelecionarMotorista", {
        id: payload.motorista.id,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarMotorista: async ({ commit }, payload) => {
    try {
      const response = await axios.putMotorista(payload);
      commit(types.EDITAR_MOTORISTA, {
        motorista: JSON.parse(response.config.data)["motorista"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarMotorista: async ({ commit }, payload) => {
    try {
      const response = await axios.getMotoristasSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_MOTORISTA, {
        motorista: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarMotoristas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getMotoristasListar(licenca);
      commit(types.LISTAR_MOTORISTAS, {
        motoristas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actPaisesSelect: async ({ commit }) => {
    try {
      const response = await axios.getPaisesSelect();
      commit(types.SELECT_PAISES, {
        paisesSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraMotorista: async ({ dispatch }, payload) => {
    const motorista = Object.assign({}, payload.motorista);
    motorista.lixeira = !motorista.lixeira;
    motorista.modificacao = payload.modificacao;
    dispatch("actEditarMotorista", {
      motorista: motorista,
      licenca: payload.licenca,
    });
  },

  actExcluirMotorista: async ({ dispatch }, payload) => {
    const motorista = Object.assign({}, payload.motorista);
    motorista.excluido = true;
    motorista.modificacao = payload.modificacao;
    dispatch("actEditarMotorista", {
      motorista: motorista,
      licenca: payload.licenca,
    });
  },

  actResetarMotorista: ({ commit }) => {
    commit(types.SELECIONAR_MOTORISTA, { motorista: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

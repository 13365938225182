export default [
  {
    name: "ContCaixasLista",
    path: "/ContCaixasLista",
    component: () => import("../_views/ContCaixasLista"),
  },
  {
    name: "ContCaixasForm",
    path: "/ContCaixasForm",
    component: () => import("../_components/ContCaixasForm"),
  },
];

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actListarEndPoints: async ({ commit }) => {
    try {
      const response = await axios.getEndPointsListar();
      commit(types.LISTAR_ENDPOINTS, {
        endPoints: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

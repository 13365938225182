import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actListarBancos: async ({ commit }) => {
    try {
      const response = await axios.getBancosListar();
      commit(types.LISTAR_BANCOS, {
        bancos: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  criarComponente: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postComponente(payload);
      payload.componente.id = response.data.id;
      commit(types.CRIAR_COMPONENTE, { componente: payload.componente });
      var componente = payload.componente;
      dispatch("selecionarComponente", { componente });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  editarComponente: async ({ commit }, payload) => {
    try {
      const response = await axios.putComponente(payload);
      commit(types.EDITAR_COMPONENTE, {
        componente: JSON.parse(response.config.data)["componente"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  listarComponentes: async ({ commit }) => {
    try {
      const response = await axios.getComponentes();
      commit(types.LISTAR_COMPONENTES, {
        componentes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  selecionarComponente: ({ commit }, payload) => {
    commit(types.SELECIONAR_COMPONENTE, payload);
  },

  ativarComponente: async ({ dispatch }, payload) => {
    const componente = Object.assign({}, payload.componente);
    componente.ativo = !componente.ativo;
    dispatch("editarComponente", { componente });
  },

  resetarComponente: ({ commit }) => {
    commit(types.SELECIONAR_COMPONENTE, { componente: undefined });
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarUsuario: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postUsuario(payload);
      payload.usuario.id = response.data.id;
      commit(types.CRIAR_USUARIO, { usuario: payload.usuario });
      var usuario = payload.usuario;
      dispatch("actSelecionarUsuario", { usuario });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarUsuario: async ({ commit }, payload) => {
    try {
      const response = await axios.putUsuario(payload);
      commit(types.EDITAR_USUARIO, {
        usuario: JSON.parse(response.config.data)["usuario"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarUsuarios: async ({ commit }, payload) => {
    try {
      const response = await axios.getUsuarios(payload);
      commit(types.LISTAR_USUARIOS, {
        usuarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarUsuario: ({ commit }, payload) => {
    commit(types.SELECIONAR_USUARIO, payload);
  },

  actAtivarUsuario: async ({ dispatch }, payload) => {
    const usuario = Object.assign({}, payload.usuario);
    usuario.ativo = !usuario.ativo;
    dispatch("actEditarUsuario", {
      usuario: usuario,
      licenca: payload.licenca,
    });
  },

  actResetarUsuario: ({ commit }) => {
    commit(types.SELECIONAR_USUARIO, { usuario: undefined });
  },

  // listarGrupos: async ({ commit }) => {
  //   try {
  //     const response = await axios.getGrupos();
  //     commit(types.LISTAR_GRUPOS, {
  //       listaGrupos: response.data.response,
  //     });
  //   } catch (erro) {
  //     commit(types.SETAR_ERRO, { erro });
  //   }
  // },

  //

  actCriarUsuariosGrupos: async ({ commit }, payload) => {
    try {
      const response = await axios.postUsuariosGrupos(payload);
      payload.usuGru.id = response.data.id;
      commit(types.CRIAR_USUARIOSGRUPOS, {
        usuGru: payload.usuGru,
        licenca: payload.licenca,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarUsuariosGrupos: async ({ commit }, payload) => {
    try {
      const response = await axios.putUsuariosGrupos(payload);
      commit(types.EDITAR_USUARIOSGRUPOS, {
        usuGru: JSON.parse(response.config.data)["usuGru"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actDeletarUsuariosGrupos: async ({ commit }, payload) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.deleteUsuariosGrupos(
        payload.usuGru.id,
        payload.licenca
      );

      commit(types.DELETAR_USUARIOSGRUPOS, {
        usuGru: payload.usuGru,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarUsuariosGrupos: async ({ commit }, payload) => {
    try {
      const response = await axios.getUsuariosGrupos(
        payload.idUsuario,
        payload.licenca
      );
      commit(types.LISTAR_USUARIOSGRUPOS, {
        usuariosGrupos: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarUsuariosGrupos: ({ commit }) => {
    var res = [];
    commit(types.LISTAR_USUARIOSGRUPOS, { usuariosGrupos: res });
  },

  actAtivarPerfMod: async ({ dispatch }, payload) => {
    const usuGru = Object.assign({}, payload.usuGru);
    usuGru.ativo = !usuGru.ativo;
    dispatch("actEditarUsuariosGrupos", { usuGru });
  },

  actSelectGrupos: async ({ commit }) => {
    try {
      const response = await axios.getSelectGrupos();
      commit(types.SELECT_GRUPOS, {
        gruposSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEnviarEmail: async ({ commit }, payload) => {
    try {
      const response = await axios.postEnviarEmail(payload);
      return response.data;
    } catch (erro) {
      commit("SETAR_ERRO", { erro });
      throw erro;
    }
  },
};

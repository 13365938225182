export default [
  {
    name: "CadProprietariosLista",
    path: "/CadProprietariosLista",
    component: () => import("../_views/CadProprietariosLista"),
  },
  {
    name: "CadProprietariosForm",
    path: "/CadProprietariosForm",
    component: () => import("../_components/CadProprietariosForm"),
  },
];

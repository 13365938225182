import apiClient from "@/services/axios";

export default {
  postPJuridica(pjuridica) {
    return apiClient.post("/finPJuridicas/cadastrar", pjuridica);
  },

  putPJuridica(pjuridica) {
    return apiClient.put("/finPJuridicas/editar", pjuridica);
  },

  getPJuridicasSelecionar(id, licenca) {
    return apiClient.get(`/finPJuridicas/selecionar/${id}/${licenca}`);
  },

  getPJuridicasListar(licenca) {
    return apiClient.get(`/finPJuridicas/listar/${licenca}`);
  },

  getPaisesSelect() {
    return apiClient.get("/tabConsultas/listarPaises");
  },

  getObterDadosCep(cep) {
    return apiClient.get(`/consGerais/cepDados/${cep}`);
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  criarConveniada: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postConveniada(payload);
      payload.conveniada.id = response.data.id;
      commit(types.CRIAR_CONVENIADA, { conveniada: payload.conveniada });
      var conveniada = payload.conveniada;
      dispatch("selecionarConveniada", { conveniada });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  editarConveniada: async ({ commit }, payload) => {
    try {
      const response = await axios.putConveniada(payload);
      commit(types.EDITAR_CONVENIADA, {
        conveniada: JSON.parse(response.config.data)["conveniada"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  listarConveniadas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getConveniadas(licenca);
      commit(types.LISTAR_CONVENIADAS, {
        conveniadas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  selecionarConveniada: ({ commit }, payload) => {
    commit(types.SELECIONAR_CONVENIADA, payload);
  },

  resetarConveniada: ({ commit }) => {
    commit(types.SELECIONAR_CONVENIADA, { conveniada: undefined });
  },

  resetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

import apiClient from "@/services/axios";

export default {
  postLicenca(licenca) {
    return apiClient.post("/adminLicencas/cadastrar", licenca);
  },

  putLicenca(licenca) {
    return apiClient.put("/adminLicencas/editar", licenca);
  },

  getLicencas() {
    return apiClient.get("/adminLicencas/listar");
  },
  getSelectPerfis() {
    return apiClient.get(`/adminPerfis/select`);
  },
};

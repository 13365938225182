export default [
  {
    name: "CadMotoristasLista",
    path: "/CadMotoristasLista",
    component: () => import("../_views/CadMotoristasLista"),
  },
  {
    name: "CadMotoristasForm",
    path: "/CadMotoristasForm",
    component: () => import("../_components/CadMotoristasForm"),
  },
];

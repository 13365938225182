import apiClient from "@/services/axios";

export default {
  postPermisso(permisso) {
    return apiClient.post("/cadPermissos/cadastrar", permisso);
  },

  putPermisso(permisso) {
    return apiClient.put("/cadPermissos/editar", permisso);
  },

  getPermissosSelecionar(id, licenca) {
    return apiClient.get(`/cadPermissos/selecionar/${id}/${licenca}`);
  },

  getPermissosListar(licenca) {
    return apiClient.get(`/cadPermissos/listar/${licenca}`);
  },

  getTransportadoresSelect(licenca) {
    return apiClient.get(`/cadTransportadores/select/${licenca}`);
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarGrupo: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postGrupo(payload);
      payload.grupo.id = response.data.id;
      commit(types.CRIAR_GRUPO, { grupo: payload.grupo });
      var grupo = payload.grupo;
      dispatch("actSelecionarGrupo", { grupo });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarGrupo: async ({ commit }, payload) => {
    try {
      const response = await axios.putGrupo(payload);
      commit(types.EDITAR_GRUPO, {
        grupo: JSON.parse(response.config.data)["grupo"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarGrupos: async ({ commit }) => {
    try {
      const response = await axios.getGrupos();
      commit(types.LISTAR_GRUPOS, {
        grupos: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarGrupo: ({ commit }, payload) => {
    commit(types.SELECIONAR_GRUPO, payload);
  },

  actAtivarGrupo: async ({ dispatch }, payload) => {
    const grupo = Object.assign({}, payload.grupo);
    grupo.ativo = !grupo.ativo;
    dispatch("actEditarGrupo", { grupo });
  },

  resetarGrupo: ({ commit }) => {
    commit(types.SELECIONAR_GRUPO, { grupo: undefined });
  },

  deletarGrupo: async ({ commit }, { grupo }) => {
    const response = await axios.deleteGrupo(grupo.id);
    commit(types.DELETAR_GRUPO, { grupo: response.data });
  },

  //

  actEditarGruposComponentes: async ({ commit }, payload) => {
    try {
      const response = await axios.putGruposComponentes(payload);
      commit(types.EDITAR_GRUPOSCOMPONENTES, {
        gruComp: JSON.parse(response.config.data)["gruComp"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarGruposComponentes: async ({ commit }, payload) => {
    try {
      const response = await axios.getGruposComponentes(payload.grupo.id);
      commit(types.LISTAR_GRUPOSCOMPONENTES, {
        gruposComponentes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actResetarGruposComponentes: ({ commit }) => {
    var res = [];
    commit(types.LISTAR_GRUPOSCOMPONENTES, { gruposComponentes: res });
  },

  actAtivarGruComp: async ({ dispatch }, payload) => {
    const gruComp = Object.assign({}, payload.gruComp);
    gruComp.ativo = !gruComp.ativo;
    dispatch("actEditarGruposComponentes", { gruComp });
  },

  actSyncGruComp: async ({ commit }, payload) => {
    var response = undefined;
    try {
      if (payload.grupo == "todos") {
        response = await axios.getSyncGruComp(payload.grupo);
      } else {
        response = await axios.getSyncGruComp(payload.grupo.id);
      }
      commit(types.LISTAR_GRUPOSCOMPONENTES, {
        gruposComponentes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_CLIENTE]: (state, { cliente }) => {
    state.clientes.push(cliente);
  },
  [types.EDITAR_CLIENTE]: (state, { cliente }) => {
    const indice = state.clientes.findIndex(
      (t) => t.id_cliente === cliente.id_cliente
    );
    state.clientes.splice(indice, 1, cliente);
  },
  [types.DELETAR_CLIENTE]: (state, { cliente }) => {
    const indice = state.clientes.findIndex((t) => t.id === cliente.id);
    state.clientes.splice(indice, 1);
  },
  [types.LISTAR_CLIENTES]: (state, { clientes }) => {
    state.clientes = clientes;
  },
  [types.SELECIONAR_CLIENTE]: (state, { cliente }) => {
    state.clienteSelecionado = cliente;
  },
  [types.LISTAR_CLIENTECOMPRAS]: (state, { clienteCompras }) => {
    state.staClienteCompras = clienteCompras;
  },
  [types.EDITAR_CLIENTECOMPRA]: (state, { clienteCompras }) => {
    state.staClienteCompras = clienteCompras;
  },
  [types.CRIAR_CLIENTECOMPRA]: (state, { clienteCompras }) => {
    state.staClienteCompras = clienteCompras;
  },
  [types.SELECIONAR_COMPRA]: (state, { compra }) => {
    state.clienteComprasSelecionado = compra;
  },
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
  [types.LISTAR_CONVENIADAS]: (state, { conveniadas }) => {
    state.conveniadas = conveniadas;
  },
  [types.SELECT_CONVENIADAS]: (state, { conveniadasSelect }) => {
    state.conveniadasSelect = conveniadasSelect;
  },
};

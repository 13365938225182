import * as types from "./mutation-types";

export default {
  [types.CRIAR_NUMERACAO]: (state, { numeracao }) => {
    state.staNumeracoes.push(numeracao);
  },
  [types.EDITAR_NUMERACAO]: (state, { numeracao }) => {
    const indice = state.staNumeracoes.findIndex((n) => n.id === numeracao.id);
    state.staNumeracoes.splice(indice, 1, numeracao);
  },
  [types.DELETAR_NUMERACAO]: (state, { numeracao }) => {
    const indice = state.staNumeracoes.findIndex((n) => n.id === numeracao.id);
    state.staNumeracoes.splice(indice, 1);
  },
  [types.LISTAR_NUMERACOES]: (state, { numeracoes }) => {
    state.staNumeracoes = numeracoes;
  },
  [types.SELECIONAR_NUMERACAO]: (state, { numeracao }) => {
    state.staNumeracaoSelect = numeracao;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};

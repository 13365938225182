import * as types from "./mutation-types";

export default {
  [types.CRIAR_CONVENIADA]: (state, { conveniada }) => {
    state.conveniadas.push(conveniada);
  },
  [types.EDITAR_CONVENIADA]: (state, { conveniada }) => {
    const indice = state.conveniadas.findIndex((t) => t.id === conveniada.id);
    state.conveniadas.splice(indice, 1, conveniada);
  },
  [types.DELETAR_CONVENIADA]: (state, { conveniada }) => {
    const indice = state.conveniadas.findIndex((t) => t.id === conveniada.id);
    state.conveniadas.splice(indice, 1);
  },
  [types.LISTAR_CONVENIADAS]: (state, { conveniadas }) => {
    state.conveniadas = conveniadas;
  },
  [types.SELECIONAR_CONVENIADA]: (state, { conveniada }) => {
    state.conveniadaSelect = conveniada;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
};

export default [
  {
    name: "CadPermissosLista",
    path: "/CadPermissosLista",
    component: () => import("../_views/CadPermissosLista"),
  },
  {
    name: "CadPermissosForm",
    path: "/CadPermissosForm",
    component: () => import("../_components/CadPermissosForm"),
  },
];

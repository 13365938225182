import apiClient from "@/services/axios";

export default {
  postEmpresa(tarefa) {
    return apiClient.post("/lojaEmpresas/cadastrar", tarefa);
  },

  putEmpresa(tarefa) {
    return apiClient.put("/lojaEmpresas/editar", tarefa);
  },

  getEmpresas(licenca) {
    return apiClient.get(`/lojaEmpresas/listar/${licenca}`);
  },
};

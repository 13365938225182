import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  criarPop: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postPop(payload);
      payload.pop.id = response.data.id;
      commit(types.CRIAR_POP, { pop: payload.pop });
      var pop = payload.pop;
      dispatch("selecionarPop", { pop });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  editarPop: async ({ commit }, payload) => {
    try {
      const response = await axios.putPop(payload);
      commit(types.EDITAR_POP, {
        pop: JSON.parse(response.config.data)["pop"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  listarPops: async ({ commit }, licenca) => {
    try {
      const response = await axios.getPops(licenca);
      commit(types.LISTAR_POPS, {
        pops: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  selecionarPop: ({ commit }, payload) => {
    commit(types.SELECIONAR_POP, payload);
  },

  resetarPop: ({ commit }) => {
    commit(types.SELECIONAR_POP, { pop: undefined });
  },
  listarUsuarios: async ({ commit }, licenca) => {
    try {
      const response = await axios.getUsuarios(licenca);
      commit(types.LISTAR_USUARIOS, {
        listaUsuarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

import apiClient from "@/services/axios";

export default {
  postLogin(data) {
    return apiClient.post("/adminUsuarios/login", data);
  },

  getPermissoes(grupo, licenca) {
    return apiClient.get(`/permissoes/recuperar/${grupo}/${licenca}`);
  },
};

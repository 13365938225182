import apiClient from "@/services/axios";

export default {
  postModulo(modulo) {
    return apiClient.post("/adminModulos/cadastrar", modulo);
  },

  putModulo(modulo) {
    return apiClient.put("/adminModulos/editar", modulo);
  },

  getModulos() {
    return apiClient.get("/adminModulos/listar");
  },

  //

  putModulosComponentes(modComp) {
    return apiClient.put("/adminModulosComp/editar", modComp);
  },

  getModulosComponentes(idModulo) {
    return apiClient.get(`/adminModulosComp/listar/${idModulo}`);
  },

  getSyncModComp(idModulo) {
    return apiClient.get(`/adminModulosComp/syncModComp/${idModulo}`);
  },
};

import * as types from "./mutation-types";

export default {
  [types.CRIAR_PROPRIETARIO]: (state, { proprietario }) => {
    state.staProprietarios.push(proprietario);
  },
  [types.EDITAR_PROPRIETARIO]: (state, { proprietario }) => {
    const indice = state.staProprietarios.findIndex(
      (t) => t.id === proprietario.id
    );
    state.staProprietarios.splice(indice, 1, proprietario);
  },
  [types.DELETAR_PROPRIETARIO]: (state, { proprietario }) => {
    const indice = state.staProprietarios.findIndex(
      (t) => t.id === proprietario.id
    );
    state.staProprietarios.splice(indice, 1);
  },
  [types.LISTAR_PROPRIETARIOS]: (state, { proprietarios }) => {
    state.staProprietarios = proprietarios;
  },
  [types.SELECIONAR_PROPRIETARIO]: (state, { proprietario }) => {
    state.staProprietarioSelect = proprietario;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },

  [types.SELECT_PAISES]: (state, { paisesSelect }) => {
    state.staPaisesSelect = paisesSelect;
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarRota: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postRota(payload);
      payload.rota.id = response.data.id;
      commit(types.CRIAR_ROTA, { rota: payload.rota });
      var rota = payload.rota;
      dispatch("actSelecionarRota", { rota });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarRota: async ({ commit }, payload) => {
    try {
      const response = await axios.putRota(payload);
      commit(types.EDITAR_ROTA, {
        rota: JSON.parse(response.config.data)["rota"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarRota: ({ commit }, payload) => {
    commit(types.SELECIONAR_ROTA, payload);
  },

  actListarRotas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getRotasListar(licenca);
      commit(types.LISTAR_ROTAS, {
        rotas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraRota: async ({ dispatch }, payload) => {
    const rota = Object.assign({}, payload.rota);
    rota.lixeira = !rota.lixeira;
    rota.modificacao = payload.modificacao;
    dispatch("actEditarRota", {
      rota: rota,
      licenca: payload.licenca,
    });
  },

  actResetarRota: ({ commit }) => {
    commit(types.SELECIONAR_ROTA, { rota: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

export const CRIAR_USUARIO = "CRIAR_USUARIO";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const DELETAR_USUARIO = "DELETAR_USUARIO";
export const LISTAR_USUARIOS = "LISTAR_USUARIOS";
export const SELECIONAR_USUARIO = "SELECIONAR_USUARIO";
export const SETAR_ERRO = "SETAR_ERRO";
export const CRIAR_USUARIOSGRUPOS = "CRIAR_USUARIOSGRUPOS";
export const EDITAR_USUARIOSGRUPOS = "EDITAR_USUARIOSGRUPOS";
export const DELETAR_USUARIOSGRUPOS = "DELETAR_USUARIOSGRUPOS";
export const LISTAR_USUARIOSGRUPOS = "LISTAR_USUARIOSGRUPOS";
export const SELECT_GRUPOS = "SELECT_GRUPOS";

import apiClient from "@/services/axios";

export default {
  postGrupo(grupo) {
    return apiClient.post("/adminGrupos/cadastrar", grupo);
  },

  putGrupo(grupo) {
    return apiClient.put("/adminGrupos/editar", grupo);
  },

  getGrupos() {
    return apiClient.get("/adminGrupos/listar");
  },

  //

  putGruposComponentes(gruComp) {
    return apiClient.put("/adminGruposComp/editar", gruComp);
  },

  getGruposComponentes(idGrupo) {
    return apiClient.get(`/adminGruposComp/listar/${idGrupo}`);
  },

  getSyncGruComp(idGrupo) {
    return apiClient.get(`/adminGruposComp/syncGruComp/${idGrupo}`);
  },
};

export default {
  staErro: undefined,
  staVeiculos: [],
  staVeiculoSelect: undefined,
  staTransportadoresSelect: [],
  staProprietariosSelect: [],
  staMotoristasSelect: [],
  staVeiculosVincSelect: [],
  staEstadosSelect: [],
};

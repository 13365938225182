import apiClient from "@/services/axios";

export default {
  postDespachante(despachante) {
    return apiClient.post("/cadDespachantes/cadastrar", despachante);
  },

  putDespachante(despachante) {
    return apiClient.put("/cadDespachantes/editar", despachante);
  },

  getDespachantesSelecionar(id, licenca) {
    return apiClient.get(`/cadDespachantes/selecionar/${id}/${licenca}`);
  },

  getDespachantesListar(licenca) {
    return apiClient.get(`/cadDespachantes/listar/${licenca}`);
  },

  getPaisesSelect() {
    return apiClient.get("/tabConsultas/listarPaises");
  },

  getObterDadosCep(cep) {
    return apiClient.get(`/consGerais/cepDados/${cep}`);
  },
};

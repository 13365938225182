export default [
  {
    name: "CadEmpresasLista",
    path: "/CadEmpresasLista",
    component: () => import("../_views/CadEmpresasLista"),
  },
  {
    name: "CadEmpresasForm",
    path: "/CadEmpresasForm",
    component: () => import("../_components/CadEmpresasForm"),
  },
];

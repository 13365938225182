export default [
  {
    name: "UtilNotasLista",
    path: "/UtilNotasLista",
    component: () => import("../_views/UtilNotasLista"),
  },
  {
    name: "UtilNotasForm",
    path: "/UtilNotasForm",
    component: () => import("../_components/UtilNotasForm"),
  },
];

import * as types from "./mutation-types";

export default {
  [types.CRIAR_COMPONENTE]: (state, { componente }) => {
    state.componentes.push(componente);
  },
  [types.EDITAR_COMPONENTE]: (state, { componente }) => {
    const indice = state.componentes.findIndex((t) => t.id === componente.id);
    state.componentes.splice(indice, 1, componente);
  },
  [types.LISTAR_COMPONENTES]: (state, { componentes }) => {
    state.componentes = componentes;
  },
  [types.SELECIONAR_COMPONENTE]: (state, { componente }) => {
    state.componenteSelecionado = componente;
  },
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },
};

import apiClient from "@/services/axios";

export default {
  postCaixas(payload) {
    return apiClient.post("/contCaixas/insert", payload);
  },

  putCaixas(payload) {
    return apiClient.put("/contCaixas/update", payload);
  },

  deleteCaixa(id, licenca) {
    return apiClient.delete(`/contCaixas/delete/${id}/${licenca}`);
  },

  getCaixasLista(licenca) {
    return apiClient.get(`/contCaixas/lista/${licenca}`);
  },

  getCaixaSelecionado(id, licenca) {
    return apiClient.get(`/contCaixas/selecionado/${id}/${licenca}`);
  },

  getAtualizaPFisicas(licenca) {
    return apiClient.get(`/finPFisicas/select/${licenca}`);
  },

  getAtualizaPJuridicas(licenca) {
    return apiClient.get(`/finPJuridicas/select/${licenca}`);
  },
};

import apiClient from "@/services/axios";

export default {
  postNota(nota) {
    return apiClient.post("/utilNotas/cadastrar", nota);
  },

  putNota(nota) {
    return apiClient.put("/utilNotas/editar", nota);
  },

  getNotasSelecionar(id, licenca) {
    return apiClient.get(`/utilNotas/selecionar/${id}/${licenca}`);
  },

  getNotasListar(licenca) {
    return apiClient.get(`/utilNotas/listar/${licenca}`);
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  criarTarefa: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postTarefa(payload);
      payload.tarefa.id = response.data.id;
      commit(types.CRIAR_TAREFA, { tarefa: payload.tarefa });
      var tarefa = payload.tarefa;
      dispatch("selecionarTarefa", { tarefa });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  editarTarefa: async ({ commit }, payload) => {
    try {
      const response = await axios.putTarefa(payload);
      // console.log(JSON.parse(response.config.data)["tarefa"]);
      commit(types.EDITAR_TAREFA, {
        tarefa: JSON.parse(response.config.data)["tarefa"],
      });
      commit(types.SELECIONAR_TAREFA, {
        tarefa: JSON.parse(response.config.data)["tarefa"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarTarefas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getTarefas(licenca);
      commit(types.LISTAR_TAREFAS, {
        tarefas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarTarefasArq: async ({ commit }, licenca) => {
    try {
      const response = await axios.getTarefasArq(licenca);
      commit(types.LISTAR_TAREFAS, {
        tarefas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarTarefa: ({ commit }, payload) => {
    commit(types.SELECIONAR_TAREFA, payload);
  },

  ativarTarefa: async ({ dispatch }, payload) => {
    const tarefa = Object.assign({}, payload.tarefa);
    tarefa.ativo = !tarefa.ativo;
    dispatch("editarTarefa", { tarefa });
  },

  resetarTarefa: ({ commit }) => {
    commit(types.SELECIONAR_TAREFA, { tarefa: undefined });
  },

  resetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },

  listarUsuarios: async ({ commit }, licenca) => {
    try {
      const response = await axios.getUsuarios(licenca);
      commit(types.LISTAR_USUARIOS, {
        listaUsuarios: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  // desAvisoMod: async ({ dispatch }, payload) => {
  //   var atualizaBanco = false;
  //   const tarefaIn = Object.assign({}, { id: payload.tarefa.id });

  //   if (
  //     payload.usuarioId == payload.tarefa.cria_usuario &&
  //     payload.tarefa.criadorAvisoMod == 1
  //   ) {
  //     Object.assign(tarefaIn, { criadorAvisoMod: 0 });
  //     atualizaBanco = true;
  //   }
  //   if (
  //     payload.usuarioId == payload.tarefa.responsavel &&
  //     payload.tarefa.responAvisoMod == 1
  //   ) {
  //     Object.assign(tarefaIn, { responAvisoMod: 0 });
  //     atualizaBanco = true;
  //   }
  //   if (
  //     payload.usuarioId == payload.tarefa.observador &&
  //     payload.tarefa.obsAvisoMod == 1
  //   ) {
  //     Object.assign(tarefaIn, { obsAvisoMod: 0 });
  //     atualizaBanco = true;
  //   }

  //   if (atualizaBanco) {
  //     dispatch("editarTarefa", { tarefa: tarefaIn, licenca: payload.licenca });
  //   }
  // },

  // desAvisoNova: async ({ dispatch }, payload) => {
  //   var atualizaBanco = false;
  //   const tarefaIn = Object.assign({}, { id: payload.tarefa.id });

  //   if (
  //     payload.usuarioId == payload.tarefa.responsavel &&
  //     payload.tarefa.responAvisoNova == 1
  //   ) {
  //     Object.assign(tarefaIn, { responAvisoNova: 0 });
  //     atualizaBanco = true;
  //   }
  //   if (
  //     payload.usuarioId == payload.tarefa.observador &&
  //     payload.tarefa.obsAvisoNova == 1
  //   ) {
  //     Object.assign(tarefaIn, { obsAvisoNova: 0 });
  //     atualizaBanco = true;
  //   }
  //   if (atualizaBanco) {
  //     dispatch("editarTarefa", { tarefa: tarefaIn, licenca: payload.licenca });
  //   }
  // },

  actModoListaTar: ({ commit }, { modo }) => {
    commit(types.MODOLISTA_TAREFAS, { stoListaTar: modo });
  },
};

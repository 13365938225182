import * as types from "./mutation-types";

export default {
  [types.CRIAR_USUARIO]: (state, { usuario }) => {
    state.staUsuarios.push(usuario);
  },
  [types.EDITAR_USUARIO]: (state, { usuario }) => {
    const indice = state.staUsuarios.findIndex((t) => t.id === usuario.id);
    state.staUsuarios.splice(indice, 1, usuario);
  },
  [types.DELETAR_USUARIO]: (state, { usuario }) => {
    const indice = state.staUsuarios.findIndex((t) => t.id === usuario.id);
    state.staUsuarios.splice(indice, 1);
  },
  [types.LISTAR_USUARIOS]: (state, { usuarios }) => {
    state.staUsuarios = usuarios;
  },
  [types.SELECIONAR_USUARIO]: (state, { usuario }) => {
    state.staUsuarioSelecionado = usuario;
  },
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.erro = erro;
  },

  //
  [types.CRIAR_USUARIOSGRUPOS]: (state, { usuGru }) => {
    state.staUsuariosGrupos.push(usuGru);
  },
  [types.EDITAR_USUARIOSGRUPOS]: (state, { usuGru }) => {
    const indice = state.staUsuariosGrupos.findIndex((t) => t.id === usuGru.id);
    state.staUsuariosGrupos.splice(indice, 1, usuGru);
  },
  [types.DELETAR_USUARIOSGRUPOS]: (state, { usuGru }) => {
    const indice = state.staUsuariosGrupos.findIndex((t) => t.id === usuGru.id);
    state.staUsuariosGrupos.splice(indice, 1);
  },
  [types.LISTAR_USUARIOSGRUPOS]: (state, { usuariosGrupos }) => {
    state.staUsuariosGrupos = usuariosGrupos;
  },
  [types.SELECT_GRUPOS]: (state, { gruposSelect }) => {
    state.staGruposSelect = gruposSelect;
  },
};

import * as types from "./mutation-types";

export default {
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
  // [types.USUARIO_INSERT]: (state, { usuario }) => {
  //   state.staCaixas.push(usuario);
  // },
  // [types.USUARIO_UPDATE]: (state, { usuario }) => {
  //   const indice = state.staCaixas.findIndex((t) => t.id === usuario.id);
  //   state.staCaixas.splice(indice, 1, usuario);
  // },
  // [types.USUARIO_DELETE]: (state, { id }) => {
  //   const indice = state.staCaixas.findIndex((t) => t.id === id);
  //   state.staCaixas.splice(indice, 1);
  // },
  // [types.USUARIOS_LISTA]: (state, { usuarios }) => {
  //   state.staCaixas = usuarios;
  // },
  [types.USUARIO_SELECIONADO]: (state, { usuario }) => {
    state.staUsuarioSelecionado = usuario;
  },
  [types.USUARIOS_LISTA]: (state, { usuarios }) => {
    state.staUsuarios = usuarios;
  },
  [types.USUARIOS_GRUPOS]: (state, { usuariosGrupos }) => {
    state.staUsuariosGrupos = usuariosGrupos;
  },
  [types.USUARIO_CAIXAS]: (state, { usuarioCaixas }) => {
    state.staUsuarioCaixas = usuarioCaixas;
  },
  [types.USUARIO_CAIXA_UPDATE]: (state, { caixa }) => {
    const indice = state.staUsuarioCaixas.findIndex((t) => t.id === caixa.id);
    state.staUsuarioCaixas.splice(indice, 1, caixa);
  },
};

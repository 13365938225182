import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";

Vue.use(Router);

const router = new Router({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  // console.log(to, from);
  next();
  // if (to.name == "Login" || to.name == "aplicacao") {
  //   next();
  // } else {
  //   console.log(to.name, this.mixPermissaoRoute(to.name));
  //   next();
  // }
});

export default router;

export default {
  erro: undefined,
  crediarios: [],
  crediarioSelecionado: undefined,
  crediarioParcelas: [],
  clientesSelect: [],
  conveniadasSelect: [],
  staListaCred: "abertos",
  staIdCliente: undefined,
};

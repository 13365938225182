import * as types from "./mutation-types";

export default {
  [types.CRIAR_DUE]: (state, { due }) => {
    state.staDues.push(due);
  },
  [types.EDITAR_DUE]: (state, { due }) => {
    const indice = state.staDues.findIndex((t) => t.id === due.id);
    state.staDues.splice(indice, 1, due);
  },
  [types.DELETAR_DUE]: (state, { due }) => {
    const indice = state.staDues.findIndex((t) => t.id === due.id);
    state.staDues.splice(indice, 1);
  },
  [types.LISTAR_DUES]: (state, { dues }) => {
    state.staDues = dues;
  },
  [types.SELECIONAR_DUE]: (state, { due }) => {
    state.staDueSelecionado = due;
  },
  [types.OBTER_DADOSCNPJ]: (state, { dadosCNPJ }) => {
    state.staDueDadosCNPJ = dadosCNPJ;
  },
  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
  [types.SELECT_MOEDAS]: (state, { moedasSelect }) => {
    state.staMoedasSelect = moedasSelect;
  },
  [types.SELECT_UNIDADELOCALRFB]: (state, { unidadeLocalRFBSelect }) => {
    state.staUnidadeLocalRFBSelect = unidadeLocalRFBSelect;
  },
  [types.SELECT_RECADUANDESP]: (state, { recAduanDespSelect }) => {
    state.staRecAduanDespSelect = recAduanDespSelect;
  },
  [types.SELECT_RECADUANEMB]: (state, { recAduanEmbSelect }) => {
    state.staRecAduanEmbSelect = recAduanEmbSelect;
  },
  [types.LISTAR_DUENFS]: (state, { dueNFs }) => {
    state.staDueNFs = dueNFs;
  },
};

import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  criarCliente: async ({ commit, dispatch }, payload) => {
    try {
      // console.log(payload);
      const response = await axios.postCliente(payload);
      // console.log(response.data);
      payload.cliente.id_cliente = response.data.id_cliente;
      commit(types.CRIAR_CLIENTE, { cliente: payload.cliente });
      var cliente = payload.cliente;
      dispatch("selecionarCliente", { cliente });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarCliente: async ({ commit }, payload) => {
    try {
      const response = await axios.putCliente(payload);
      commit(types.EDITAR_CLIENTE, {
        cliente: JSON.parse(response.config.data)["cliente"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  listarClientes: async ({ commit }, licenca) => {
    try {
      const response = await axios.getClientes(licenca);
      commit(types.LISTAR_CLIENTES, {
        clientes: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  selecionarCliente: ({ commit }, payload) => {
    commit(types.SELECIONAR_CLIENTE, payload);
  },

  resetarCliente: ({ commit }) => {
    commit(types.SELECIONAR_CLIENTE, { cliente: undefined });
  },

  actCriarClienteCompra: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postClienteCompra(payload);
      payload.compra.id = response.data.id;
      commit(types.CRIAR_CLIENTECOMPRA, { compra: payload.compra });
      var compra = payload.compra;
      dispatch("selecionarCompra", { compra });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarClienteCompra: async ({ commit }, payload) => {
    try {
      const response = await axios.putClienteCompra(payload);
      commit(types.EDITAR_CLIENTECOMPRA, {
        compra: JSON.parse(response.config.data)["compra"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarClienteCompras: async ({ commit }, payload) => {
    try {
      const response = await axios.getClienteCompras(
        payload.id_cliente,
        payload.licenca
      );
      commit(types.LISTAR_CLIENTECOMPRAS, {
        clienteCompras: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  selecionarCompra: ({ commit }, payload) => {
    commit(types.SELECIONAR_COMPRA, payload);
  },

  resetarCompra: ({ commit }) => {
    commit(types.SELECIONAR_COMPRA, { compra: undefined });
  },

  listarConveniadas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getConveniadas(licenca);
      commit(types.LISTAR_CONVENIADAS, {
        conveniadas: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
  selectConveniadas: async ({ commit }, licenca) => {
    try {
      const response = await axios.getSelectConveniadas(licenca);
      commit(types.SELECT_CONVENIADAS, {
        conveniadasSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },
};

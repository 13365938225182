import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarVeiculo: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postVeiculo(payload);
      payload.veiculo.id = response.data.id;
      commit(types.CRIAR_VEICULO, { veiculo: payload.veiculo });
      var veiculo = payload.veiculo;
      dispatch("actSelecionarVeiculo", { veiculo });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarVeiculo: async ({ commit }, payload) => {
    try {
      const response = await axios.putVeiculo(payload);
      commit(types.EDITAR_VEICULO, {
        veiculo: JSON.parse(response.config.data)["veiculo"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarVeiculo: async ({ commit }, payload) => {
    try {
      const response = await axios.getVeiculosSelecionar(
        payload.id,
        payload.licenca
      );
      commit(types.SELECIONAR_VEICULO, {
        veiculo: response.data.response[0],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actListarVeiculos: async ({ commit }, licenca) => {
    try {
      const response = await axios.getVeiculosListar(licenca);
      commit(types.LISTAR_VEICULOS, {
        veiculos: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actTransportadoresSelect: async ({ commit }, licenca) => {
    try {
      const response = await axios.getTransportadoresSelect(licenca);
      commit(types.SELECT_TRANSPORTADORES, {
        transportadoresSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actProprietariosSelect: async ({ commit }, licenca) => {
    try {
      const response = await axios.getProprietariosSelect(licenca);
      commit(types.SELECT_PROPRIETARIOS, {
        proprietariosSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actMotoristasSelect: async ({ commit }, licenca) => {
    try {
      const response = await axios.getMotoristasSelect(licenca);
      commit(types.SELECT_MOTORISTAS, {
        motoristasSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actVeiculosSelect: async ({ commit }, licenca) => {
    try {
      const response = await axios.getVeiculosSelect(licenca);
      commit(types.SELECT_VEICULOS, {
        veiculosSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEstadosSelect: async ({ commit }) => {
    try {
      const response = await axios.getEstadosSelect();
      commit(types.SELECT_ESTADOS, {
        estadosSelect: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraVeiculo: async ({ dispatch }, payload) => {
    const veiculo = Object.assign({}, payload.veiculo);
    veiculo.lixeira = !veiculo.lixeira;
    veiculo.modificacao = payload.modificacao;
    dispatch("actEditarVeiculo", {
      veiculo: veiculo,
      licenca: payload.licenca,
    });
  },

  actResetarVeiculo: ({ commit }) => {
    commit(types.SELECIONAR_VEICULO, { veiculo: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};

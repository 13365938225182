import * as types from "./mutation-types";

export default {
  [types.LISTAR_RECINTOADUANEIRO]: (state, { recintoaduaneiro }) => {
    state.staRecintoAduaneiro = recintoaduaneiro;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};

export const CRIAR_VEICULO = "CRIAR_VEICULO";
export const EDITAR_VEICULO = "EDITAR_VEICULO";
export const DELETAR_VEICULO = "DELETAR_VEICULO";
export const LISTAR_VEICULOS = "LISTAR_VEICULOS";
export const SELECIONAR_VEICULO = "SELECIONAR_VEICULO";
export const SETAR_ERRO = "SETAR_ERRO";
export const SELECT_TRANSPORTADORES = "SELECT_TRANSPORTADORES";
export const SELECT_PROPRIETARIOS = "SELECT_PROPRIETARIOS";
export const SELECT_MOTORISTAS = "SELECT_MOTORISTAS";
export const SELECT_VEICULOS = "SELECT_VEICULOS";
export const SELECT_ESTADOS = "SELECT_ESTADOS";

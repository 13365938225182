export default [
  {
    name: "CadVeiculosLista",
    path: "/CadVeiculosLista",
    component: () => import("../_views/CadVeiculosLista"),
  },
  {
    name: "CadVeiculosForm",
    path: "/CadVeiculosForm",
    component: () => import("../_components/CadVeiculosForm"),
  },
];

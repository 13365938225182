import axios from "axios";

// const https = require("https");

// process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

// const agent = new https.Agent({
//   rejectUnauthorized: false,
//   ca: false,
// });

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
  // httpsAgent: agent,
});

apiClient.interceptors.request.use(
  function (config) {
    // const token = sessionStorage.getItem("token");
    const token = window.is_token;
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);

export default apiClient;

export default [
  {
    name: "ContUsuariosLista",
    path: "/ContUsuariosLista",
    component: () => import("../_views/ContUsuariosLista"),
  },
  {
    name: "ContUsuariosForm",
    path: "/ContUsuariosForm",
    component: () => import("../_components/ContUsuariosForm"),
  },
];

import apiClient from "@/services/axios";

export default {
  postNumeracao(numeracao) {
    return apiClient.post("/cadNumeracoes/cadastrar", numeracao);
  },

  putNumeracao(numeracao) {
    return apiClient.put("/cadNumeracoes/editar", numeracao);
  },

  getNumeracoesSelecionar(id, licenca) {
    return apiClient.get(`/cadNumeracoes/selecionar/${id}/${licenca}`);
  },

  getNumeracoesListar(licenca) {
    return apiClient.get(`/cadNumeracoes/listar/${licenca}`);
  },
};
